import axios from 'axios';

import { SOFIA_API } from '../config';

const hostApi = SOFIA_API;

const axiosInstance = axios.create({
  baseURL: hostApi,
});

axiosInstance.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  req.headers['Content-Type'] = 'application/json';
  req.headers.Language = `${localStorage.getItem('i18nextLng')}`;
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
