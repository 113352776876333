function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const { REACT_APP_SOFIA_API } = process.env;

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  verifyLink: path(ROOTS_AUTH, '/verify-link'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    password: path(ROOTS_DASHBOARD, '/user/password'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    checkout: path(ROOTS_DASHBOARD, '/user/checkout'),
  },

  project: {
    root: path(ROOTS_DASHBOARD, '/project'),
    shop: path(ROOTS_DASHBOARD, '/project/grid'),
    list: path(ROOTS_DASHBOARD, '/project/list'),
    new: path(ROOTS_DASHBOARD, '/project/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/project/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/project/${id}/edit`),
  },
  // invoice: {
  //   root: path(ROOTS_DASHBOARD, '/invoice'),
  //   list: path(ROOTS_DASHBOARD, '/invoice/list'),
  //   new: path(ROOTS_DASHBOARD, '/invoice/new'),
  //   view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
  //   edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
  //   demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
  //   demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  // },
  questions: {
    root: path(ROOTS_DASHBOARD, '/questions'),
    list: path(ROOTS_DASHBOARD, '/questions/list'),
    new: path(ROOTS_DASHBOARD, '/questions/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/questions/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/questions/${id}/edit`),
  },
};

export const PATH_DOCS = '';
export const PATH_SHOPIFY_APP_INSTALL = `${REACT_APP_SOFIA_API}/api/shopify`;
export const PATH_SHOPIFY_APP_INSTALL_DEV = '';
