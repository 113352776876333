const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: ['JavaScript version', 'TypeScript version', 'Design Resources', 'Commercial applications'],
  icons: ['/assets/images/home/ic_sketch.svg', '/assets/images/home/ic_figma.svg', '/assets/images/home/ic_js.svg', '/assets/images/home/ic_ts.svg'],
}));

// https://merchant-ui-api.stripe.com/pricing-table/prctbl_1O3TdyErFTuL4t3qPlRGzNfi?key=pk_live_51J0C5HErFTuL4t3qBRsXJZSGusKn8wWoyKCZeGBLz9xV2YytfXJYZdWGGkCXucvU4RgdIrJnsg8mFjh7yhmw1EOh003aznXkY4

export const _stripePricingTable = [
  {
    address_collection: {
      id: 'address_collection_id_prctblitm_PCJDGHFe5Fq7l9',
      address_collection_setting: 'auto',
      allowed_shipping_countries: null,
    },
    adjustable_quantity: {
      id: 'adjustable_quantity_id_prctblitm_PCJDGHFe5Fq7l9',
      enabled: false,
      maximum: 1,
      minimum: 0,
    },
    allow_promotion_codes: true,
    amount: '900',
    automatic_tax: {
      id: 'automatic_tax_id_prctblitm_PCJDGHFe5Fq7l9',
      enabled: true,
    },
    call_to_action: 'start_trial',
    call_to_action_link:
      'https://checkout.stripe.com/c/pay/prctbl_1O3TdyErFTuL4t3qPlRGzNfi/prctblitm_PCJDGHFe5Fq7l9#fidkdWxOYHwnPyd1blppbHNgWjA0TzVGME1Ad0NRcEkxcTZ0R1d2XU9fVkJwdk5rPXJSanxORl9gQkdJfzx9UzdcfHFjXU9cX2FSQkJuRl1wZnNQMVdiYUx3T2t2Yj1oQ29tMnxtaHI0QEptNTU2ZH9rXW5cMSd4JSUl',
    confirmation_page: {
      id: 'confirmation_page_id_prctblitm_PCJDGHFe5Fq7l9',
      custom_message: null,
      redirect_url: 'https://sofia.eudaimoniatech.io/dashboard/user/checkout',
      type: 'redirect',
    },
    consent_collection: {
      payment_method_reuse_agreement: null,
      promotions: 'none',
      terms_of_service: 'required',
    },
    currency: 'eur',
    custom_fields: [],
    feature_list: ['1 chatbot personalizzato', '30 pagine', '15.000 parole', '30 query/mese'],
    highlight_text: null,
    image_url:
      'https://stripe-camo.global.ssl.fastly.net/812c2f794839ee372394df6bc04a9c5b780924014b4a3258f513969b731c8e76/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a64463878536a42444e556846636b5a556455773064444e7866475a7358327870646d5666526e6c42566a465063335533565864365132354a55454a50556d4e685333643430307833776e36774f61',
    is_highlight: false,
    name: 'Personal Plan',
    payment_method_collection: 'always',
    phone_number_collection: {
      id: 'phone_number_collection_id_prctblitm_PCJDGHFe5Fq7l9',
      enabled: false,
    },
    price_id: 'price_1NcZRYErFTuL4t3q4m3KMu9W',
    price_type: 'recurring',
    product_description: '7 giorni gratis',
    product_id: 'prod_OPOEe7frzwIi0y',
    recurring: {
      id: 'recurring-1-month',
      all_tiers: null,
      billing_scheme: 'per_unit',
      interval: 'month',
      interval_count: 1,
      interval_in_seconds: 2678400,
      tier_prices: null,
      tier_starting_prices: null,
      tiers: null,
      tiers_mode: null,
      usage_type: 'licensed',
    },
    tax_id_collection: {
      enabled: true,
    },
    token: 'prctblitm_PCJDGHFe5Fq7l9',
    trial_period_days: 7,
    trial_settings: {
      end_behavior: {
        missing_payment_method: 'pause',
      },
    },
    unit_label: 'per utente',
  },
  {
    address_collection: {
      id: 'address_collection_id_prctblitm_PCJDqmwqtlyZXC',
      address_collection_setting: 'auto',
      allowed_shipping_countries: null,
    },
    adjustable_quantity: {
      id: 'adjustable_quantity_id_prctblitm_PCJDqmwqtlyZXC',
      enabled: false,
      maximum: 1,
      minimum: 0,
    },
    allow_promotion_codes: true,
    amount: '2900',
    automatic_tax: {
      id: 'automatic_tax_id_prctblitm_PCJDqmwqtlyZXC',
      enabled: true,
    },
    call_to_action: 'start_trial',
    call_to_action_link:
      'https://checkout.stripe.com/c/pay/prctbl_1O3TdyErFTuL4t3qPlRGzNfi/prctblitm_PCJDqmwqtlyZXC#fidkdWxOYHwnPyd1blppbHNgWjA0TzVGME1Ad0NRcEkxcTZ0R1d2XU9fVkJwdk5rPXJSanxORl9gQkdJfzx9UzdcfHFjXU9cX2FSQkJuRl1wZnNQMVdiYUx3T2t2Yj1oQ29tMnxtaHI0QEptNTU2ZH9rXW5cMSd4JSUl',
    confirmation_page: {
      id: 'confirmation_page_id_prctblitm_PCJDqmwqtlyZXC',
      custom_message: null,
      redirect_url: 'https://sofia.eudaimoniatech.io/dashboard/user/checkout',
      type: 'redirect',
    },
    consent_collection: {
      payment_method_reuse_agreement: null,
      promotions: 'none',
      terms_of_service: 'required',
    },
    currency: 'eur',
    custom_fields: [],
    feature_list: ['2 chatbot personalizzati', '400 pagine/chatbot', '200.000 parole/chatbot', '400 query/mese'],
    highlight_text: 'recommended',
    image_url:
      'https://stripe-camo.global.ssl.fastly.net/8ea534cec70c3e0d63e65979861b0caccf590538cde25cda9918171a6e7b6825/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a64463878536a42444e556846636b5a556455773064444e7866475a735833526c633352665331525a526b5a746131703056306f78526a5a73516b5a52546a4e5951544e483030787779666e416149',
    is_highlight: true,
    name: 'Base Plan',
    payment_method_collection: 'always',
    phone_number_collection: {
      id: 'phone_number_collection_id_prctblitm_PCJDqmwqtlyZXC',
      enabled: false,
    },
    price_id: 'price_1NcZRnErFTuL4t3qOSGW1NgC',
    price_type: 'recurring',
    product_description: '7 giorni gratis',
    product_id: 'prod_OPOEZWy9Tr0OMD',
    recurring: {
      id: 'recurring-1-month',
      all_tiers: null,
      billing_scheme: 'per_unit',
      interval: 'month',
      interval_count: 1,
      interval_in_seconds: 2678400,
      tier_prices: null,
      tier_starting_prices: null,
      tiers: null,
      tiers_mode: null,
      usage_type: 'licensed',
    },
    tax_id_collection: {
      enabled: true,
    },
    token: 'prctblitm_PCJDqmwqtlyZXC',
    trial_period_days: 7,
    trial_settings: {
      end_behavior: {
        missing_payment_method: 'pause',
      },
    },
    unit_label: null,
  },
  {
    address_collection: {
      id: 'address_collection_id_prctblitm_PCJDhvOLBpS2C5',
      address_collection_setting: 'auto',
      allowed_shipping_countries: null,
    },
    adjustable_quantity: {
      id: 'adjustable_quantity_id_prctblitm_PCJDhvOLBpS2C5',
      enabled: false,
      maximum: 1,
      minimum: 0,
    },
    allow_promotion_codes: true,
    amount: '4900',
    automatic_tax: {
      id: 'automatic_tax_id_prctblitm_PCJDhvOLBpS2C5',
      enabled: true,
    },
    call_to_action: 'start_trial',
    call_to_action_link:
      'https://checkout.stripe.com/c/pay/prctbl_1O3TdyErFTuL4t3qPlRGzNfi/prctblitm_PCJDhvOLBpS2C5#fidkdWxOYHwnPyd1blppbHNgWjA0TzVGME1Ad0NRcEkxcTZ0R1d2XU9fVkJwdk5rPXJSanxORl9gQkdJfzx9UzdcfHFjXU9cX2FSQkJuRl1wZnNQMVdiYUx3T2t2Yj1oQ29tMnxtaHI0QEptNTU2ZH9rXW5cMSd4JSUl',
    confirmation_page: {
      id: 'confirmation_page_id_prctblitm_PCJDhvOLBpS2C5',
      custom_message: null,
      redirect_url: 'https://sofia.eudaimoniatech.io/dashboard/user/checkout',
      type: 'redirect',
    },
    consent_collection: {
      payment_method_reuse_agreement: null,
      promotions: 'none',
      terms_of_service: 'required',
    },
    currency: 'eur',
    custom_fields: [],
    feature_list: ['3 chatbot personalizzati', '1.000 pagine/chatbot', '500.000 parole/chatbot', '600 query/mese'],
    highlight_text: null,
    image_url:
      'https://stripe-camo.global.ssl.fastly.net/44cdf2023f3a9a467c701f91f0d129338b279880f27410b69d36c1e56ea3ca48/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a64463878536a42444e556846636b5a556455773064444e7866475a7358327870646d566655336b344e556459566e6458646d524c59544e535531424f53554a7864573175303032516e7430365077',
    is_highlight: false,
    name: 'Professional Plan',
    payment_method_collection: 'always',
    phone_number_collection: {
      id: 'phone_number_collection_id_prctblitm_PCJDhvOLBpS2C5',
      enabled: false,
    },
    price_id: 'price_1NcZRsErFTuL4t3qyn7DqYWI',
    price_type: 'recurring',
    product_description: '7 giorni gratis',
    product_id: 'prod_OPOEDTzwPhdiRG',
    recurring: {
      id: 'recurring-1-month',
      all_tiers: null,
      billing_scheme: 'per_unit',
      interval: 'month',
      interval_count: 1,
      interval_in_seconds: 2678400,
      tier_prices: null,
      tier_starting_prices: null,
      tiers: null,
      tiers_mode: null,
      usage_type: 'licensed',
    },
    tax_id_collection: {
      enabled: true,
    },
    token: 'prctblitm_PCJDhvOLBpS2C5',
    trial_period_days: 7,
    trial_settings: {
      end_behavior: {
        missing_payment_method: 'pause',
      },
    },
    unit_label: 'per utente',
  },
  {
    address_collection: {
      id: 'address_collection_id_prctblitm_PCJD1H7aFX6sBt',
      address_collection_setting: 'auto',
      allowed_shipping_countries: null,
    },
    adjustable_quantity: {
      id: 'adjustable_quantity_id_prctblitm_PCJD1H7aFX6sBt',
      enabled: false,
      maximum: 1,
      minimum: 0,
    },
    allow_promotion_codes: true,
    amount: '8900',
    automatic_tax: {
      id: 'automatic_tax_id_prctblitm_PCJD1H7aFX6sBt',
      enabled: true,
    },
    call_to_action: 'start_trial',
    call_to_action_link:
      'https://checkout.stripe.com/c/pay/prctbl_1O3TdyErFTuL4t3qPlRGzNfi/prctblitm_PCJD1H7aFX6sBt#fidkdWxOYHwnPyd1blppbHNgWjA0TzVGME1Ad0NRcEkxcTZ0R1d2XU9fVkJwdk5rPXJSanxORl9gQkdJfzx9UzdcfHFjXU9cX2FSQkJuRl1wZnNQMVdiYUx3T2t2Yj1oQ29tMnxtaHI0QEptNTU2ZH9rXW5cMSd4JSUl',
    confirmation_page: {
      id: 'confirmation_page_id_prctblitm_PCJD1H7aFX6sBt',
      custom_message: null,
      redirect_url: 'https://sofia.eudaimoniatech.io/dashboard/user/checkout',
      type: 'redirect',
    },
    consent_collection: {
      payment_method_reuse_agreement: null,
      promotions: 'none',
      terms_of_service: 'required',
    },
    currency: 'eur',
    custom_fields: [],
    feature_list: ['6 chatbot personalizzati', '2.000 pagine/chatbot', '1.000.000 di parole/chatbot', '2.000 query/mese'],
    highlight_text: null,
    image_url:
      'https://stripe-camo.global.ssl.fastly.net/74dd156d5fce9ce50390fa32933be3bdb0db3d33c03bb3b3835030fe6d6afe8d/68747470733a2f2f66696c65732e7374726970652e636f6d2f6c696e6b732f4d44423859574e6a64463878536a42444e556846636b5a556455773064444e7866475a7358327870646d5666515456776445397864445a6d53456c705a5642544d4755774f555a595954644530304a645947444c4864',
    is_highlight: false,
    name: 'Advanced Plan',
    payment_method_collection: 'always',
    phone_number_collection: {
      id: 'phone_number_collection_id_prctblitm_PCJD1H7aFX6sBt',
      enabled: false,
    },
    price_id: 'price_1NcZRyErFTuL4t3qYb3l9BGC',
    price_type: 'recurring',
    product_description: '7 giorni gratis',
    product_id: 'prod_OPOE4xCVQAc2dc',
    recurring: {
      id: 'recurring-1-month',
      all_tiers: null,
      billing_scheme: 'per_unit',
      interval: 'month',
      interval_count: 1,
      interval_in_seconds: 2678400,
      tier_prices: null,
      tier_starting_prices: null,
      tiers: null,
      tiers_mode: null,
      usage_type: 'licensed',
    },
    tax_id_collection: {
      enabled: true,
    },
    token: 'prctblitm_PCJD1H7aFX6sBt',
    trial_period_days: 7,
    trial_settings: {
      end_behavior: {
        missing_payment_method: 'pause',
      },
    },
    unit_label: 'per utente',
  },
];
