import { Box, Card, Container, Link, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import Image from '../../components/Image';
// components
import Page from '../../components/Page';
// hooks
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
// routes
import { PATH_AUTH } from '../../routes/paths';
// sections
import { LoginForm } from '../../sections/auth/login';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  return (
    // @ts-ignore
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Box component="img" src="/logo/logo_full.svg" sx={{ maxWidth: 160, cursor: 'pointer' }} />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {t('login.dont_have_an_account')} {''}
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                {t('login.sign_up')}
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {false && mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              {t('login.hi_welcome_back')}
            </Typography>
            <Image visibleByDefault disabledEffect src="/assets/illustrations/illustration_login.png" alt="login" ratio={undefined} sx={undefined} />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t('login.sign_in')}
                </Typography>
                <Typography gutterBottom variant="subtitle1">
                  Sofia GPT AI Knowledge Base v1.2.0
                </Typography>
                {/* <Typography sx={{ color: 'text.secondary' }}>{t('login.login_to_your_account')}</Typography> */}
              </Box>
              {/* ToDO: change icons */}
              {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api-dev.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip> */}
            </Stack>

            <LoginForm />
            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {t('login.dont_have_an_account')} {''}
                <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                  {t('login.get_started')}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
