import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
// hooks
import Label from 'src/components/Label';
import * as Yup from 'yup';

// utils
// _mock
import { countries } from '../../../../_mock';
// components
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import MyAvatar from '../../../../components/MyAvatar';
import useAuth from '../../../../hooks/useAuth';
import useLocales from '../../../../hooks/useLocales';

export default function AccountGeneral() {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  // first_name_is_required: `First name is required`,
  // last_name_is_required: `Last name is required`,
  // email_address: `Email address`,
  // first_name: `First name`,
  // last_name: `Last name`,
  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required(t('first_name_is_required')),
  });

  // console.log('user', user);
  const defaultValues = {
    displayName: user?.displayName || '',
    email: user?.email || '',
    photoURL: user?.photoURL || '',
    phoneNumber: user?.phoneNumber || '',
    country: user?.address.country || '',
    address: user?.address.line1 || '',
    state: user?.address.state || '',
    city: user?.address.city || '',
    zipCode: user?.address.postal_code || '',
    about: user?.about || '',
    isPublic: user?.isPublic || false,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <MyAvatar
              sx={{
                width: 100,
                height: 100,
                mt: 2,
                mx: 'auto',
                textAlign: 'center',
                fontSize: 40,
              }}
            />
            {/* <RHFUploadAvatar
              name="photoURL"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            /> */}
            <Label display="block" variant="caption" sx={{ mt: 3, mb: 2, textAlign: 'center' }}>
              {user.displayName}
            </Label>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user.email}
            </Typography>
            {/* <RHFSwitch name="isPublic" labelPlacement="start" label="Public Profile" sx={{ mt: 5 }} /> */}
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              <RHFTextField name="displayName" label={t('first_name')} disabled />
              <RHFTextField name="email" label={t('email_address')} disabled />

              <RHFTextField name="phoneNumber" label={t('phone_number')} disabled />
              <RHFTextField name="address" label={t('address')} disabled />

              <RHFSelect name="country" label={t('country')} placeholder={t('country')} disabled>
                <option value="" />
                {countries.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.label}
                  </option>
                ))}
              </RHFSelect>

              <RHFTextField name="state" label={t('state')} disabled />

              <RHFTextField name="city" label={t('city')} disabled />
              <RHFTextField name="zipCode" label={t('zip_code')} disabled />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <RHFTextField name="about" multiline rows={4} maxRows={4} label={t('about')} disabled />
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled>
                {t('save_changes')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
