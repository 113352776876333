// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Card, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// components
import useLocales from 'src/hooks/useLocales';
import * as Yup from 'yup';

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';
// hooks
import useAuth from '../../../../hooks/useAuth';
// redux
import { upsertApiKeys } from '../../../../redux/slices/account';
import { useDispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';

const shopifyColor = lightGreen[700];

export default function AccountOpenAiApiKeys() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { t } = useLocales();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  // useEffect(() => {
  //   dispatch(setAccount(user));
  // }, [dispatch, user]);

  const { account } = useSelector((state) => state.account);

  // const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const ChangeOpenAiApiSchema = Yup.object().shape({
    chatGptPluginToken: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      chatGptPluginToken: account?.apiKeys?.chatGptPluginToken || user?.apiKeys?.chatGptPluginToken || t('generate_token'),
    }),
    [account, user, t]
  );

  const methods = useForm({
    resolver: yupResolver(ChangeOpenAiApiSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // refresh_token: `Aggiorna token`,
  // token_updated_successfully: `Token aggiornato con successo`,
  // token_update_failed: `Aggiornamento token non riuscito`,

  // const onRefreshToken = async () => {
  //   try {
  //     const openAiAccessToken = localStorage.getItem('openAiAccessToken');
  //     const newApiKey = { chatGptPluginToken: openAiAccessToken };
  //     if (newApiKey.chatGptPluginToken !== '') {
  //       await dispatch(upsertApiKeys(user, newApiKey));
  //       enqueueSnackbar(t('token_updated_successfully'));
  //       navigate(PATH_DASHBOARD.user.account);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar(t('token_update_failed'), { variant: 'error' });
  //   }
  // };

  const onSubmit = async (data) => {
    try {
      const openAiAccessToken = localStorage.getItem('openAiAccessToken');
      const newApiKey = { chatGptPluginToken: openAiAccessToken };
      if (newApiKey.chatGptPluginToken !== '') {
        await dispatch(upsertApiKeys(user, newApiKey));
        enqueueSnackbar(t('token_updated_successfully'));
        await new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
          window.location.href = PATH_DASHBOARD.user.account;
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t('token_update_failed'), { variant: 'error' });
    }
  };

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.h4,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    textAlign: 'left',
  }));

  return (
    <>
      <Card sx={{ p: 3, mb: 6 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} alignItems="flex-start">
            <LabelStyle>Chat GPT Plugin Token</LabelStyle>
            <RHFTextField
              disabled
              name="chatGptPluginToken"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)} loading={isSubmitting}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                    <IconButton edge="end" type="submit" loading={isSubmitting} label={t('refresh_token')}>
                      <Iconify icon={'eva:refresh-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </FormProvider>
      </Card>

      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods} onSubmit={() => {}}>
          <Stack spacing={3} alignItems="flex-end">
            <RHFTextField disabled name="openAiApiKey" type="password" enabled={false} label={`***OpenaAI Custom API Key ${t('available_only_on_enterprise_plan')}***`} />
            <RHFTextField
              disabled
              name="pineconeApiKey"
              type="password"
              enabled={false}
              label={`***Pinecone Vector Database Custom API Key ${t('available_only_on_enterprise_plan')}***`}
            />
            <RHFTextField disabled name="googgleCloudApiKey" type="password" enabled={false} label={`***Google Cloud Custom API Key${t('available_only_on_enterprise_plan')}***`} />
          </Stack>
        </FormProvider>
      </Card>
    </>
  );
}
