// @mui
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// Icons (assuming you have Iconify or similar installed)

RHFTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  InputProps: PropTypes.object,
};

export default function RHFTextField({ name, label, multiline, rows, minRows, maxRows, size, InputProps, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          fullWidth
          multiline={multiline}
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          size={size}
          InputProps={InputProps}
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
