// @mui
import { Stack, Typography } from '@mui/material';

// routes
// assets
import { DocIllustration } from '../../../assets';
// hooks
import useLocales from '../../../hooks/useLocales';
// import { IntercomProvider, useIntercom } from 'react-use-intercom';
// import { Livechat } from '../../../components/chatwoot';

export default function NavbarDocs() {
  const { t } = useLocales();
  // const [isChatOpen, setIsChatOpen] = useState(true);

  // const toggleChat = () => {
  //   setIsChatOpen(!isChatOpen);
  // };

  // const showIntercom =  localStorage.getItem('show_intercom_on_refresh') != 'false';

  // const {
  //   showNewMessage,
  //   showSpace
  // } = useIntercom();

  // const handleNewMessagesWithContent = () => showNewMessage('Ho bisogno di aiuto!');

  // useEffect(() => {
  //   if (showIntercom) {
  //     showSpace('home');
  //   }
  // }, []);

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 10,
        mt: 5,
        width: 1,
        textAlign: 'center',
        display: 'block',
      }}
    >
      <DocIllustration sx={{ width: 1 }} />
      <div>
        <Typography gutterBottom variant="subtitle1">
          Sofia GPT
        </Typography>
        <Typography gutterBottom variant="subtitle2">
          {process.env.REACT_APP_SOFIA_VERSION}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {t('docs.description')}
          {/* <Link
            variant="body2"
            target="_blank"
            rel="noopener"
            sx={{ cursor: 'pointer' }}
            onClick={handleNewMessagesWithContent}>{t('docs.documentation')}</Link> */}
        </Typography>
        {/* <Livechat isChatOpen={isChatOpen} /> */}
      </div>
    </Stack>
  );
}
