import { Box, TextField, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import FileRecentItem from '../../sections/@dashboard/file-manager/file-recent-item';
import { fileData } from '../file-thumbnail';

const ITEMS_PER_LOAD = 10;

const MultiFilePreview = React.memo(({ thumbnail, files, onRemove, sx }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [displayedItems, setDisplayedItems] = useState(ITEMS_PER_LOAD);

  const sortedFiles = useMemo(() => {
    return [...files].sort((a, b) => {
      if (a.path && !a.created_at && (!b.path || b.created_at)) return -1;
      if (b.path && !b.created_at && (!a.path || a.created_at)) return 1;
      const dateA = a.created_at ? new Date(a.created_at) : new Date(0);
      const dateB = b.created_at ? new Date(b.created_at) : new Date(0);
      return dateB - dateA;
    });
  }, [files]);

  const filteredFiles = useMemo(() => {
    return sortedFiles.filter((file) => {
      const fileName = file.file_name || file.path || '';
      return fileName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [sortedFiles, searchTerm]);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
    setDisplayedItems(ITEMS_PER_LOAD);
  }, []);

  const loadMore = useCallback(() => {
    setDisplayedItems((prevItems) => prevItems + ITEMS_PER_LOAD);
  }, []);

  const handleRemove = useCallback(
    (file) => {
      onRemove(file);
    },
    [onRemove]
  );

  return (
    <Box>
      <TextField fullWidth variant="outlined" placeholder="Search files..." value={searchTerm} onChange={handleSearchChange} sx={{ mb: 2 }} />
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="body3" sx={{ mb: 2 }}>
          Total files: {files.length} | Search results: {filteredFiles.length}
        </Typography>
      </Box>
      <InfiniteScroll dataLength={displayedItems} next={loadMore} hasMore={displayedItems < filteredFiles.length} loader={<h4>Loading...</h4>} scrollableTarget="scrollableDiv">
        <AnimatePresence initial={false}>
          {filteredFiles.slice(0, displayedItems).map((file, index) => {
            const { key, name, size = 0, status, created_at } = fileData(file);
            const fileName = file.file_name || file.path || name || 'Untitled';
            const uniqueKey = `${key || file.id || index}-${fileName}-${created_at || Date.now()}`;

            return (
              <FileRecentItem
                key={uniqueKey}
                file={{
                  ...file,
                  name: fileName,
                  preview: file.preview || file.url,
                  size,
                  status,
                  created_at: created_at || Date.now(),
                }}
                sx={{
                  my: 1,
                  py: 1,
                  px: 1.5,
                  borderRadius: 1,
                  border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
                  ...sx,
                }}
                onDelete={() => handleRemove(file)}
              />
            );
          })}
        </AnimatePresence>
      </InfiniteScroll>
    </Box>
  );
});

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  sx: PropTypes.object,
  thumbnail: PropTypes.bool,
};

MultiFilePreview.displayName = 'MultiFilePreview';

export default MultiFilePreview;
