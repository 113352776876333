// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
//
// import FileManagerShareDialog from './file-manager-share-dialog';
// import FileManagerInvitedItem from './file-manager-invited-item';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { sentenceCase } from 'change-case';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import FileThumbnail, { fileFormat } from 'src/components/file-thumbnail';
// components
import Scrollbar from 'src/components/Scrollbar';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { fNumber } from 'src/utils/format-number';
// utils
import { fData } from 'src/utils/formatNumber';

import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
import useLocales from '../../../hooks/useLocales';

export default function FileManagerFileDetails({
  item,
  open,
  favorited,
  //
  onFavorite,
  onCopyLink,
  onClose,
  onDelete,
  ...other
}) {
  const theme = useTheme();

  const { t } = useLocales();

  const { name, size, url, type, shared, modifiedAt, status, last_chunk_processed, message } = item;

  const hasShared = shared && !!shared.length;

  const toggleTags = useBoolean(true);

  const share = useBoolean();

  const properties = useBoolean(true);

  const [inviteEmail, setInviteEmail] = useState('');

  const [tags, setTags] = useState(item.tags?.slice(0, 3));

  const handleChangeInvite = useCallback((event) => {
    setInviteEmail(event.target.value);
  }, []);

  const handleChangeTags = useCallback((newValue) => {
    setTags(newValue);
  }, []);

  // const renderTags = (
  //   <Stack spacing={1.5}>
  //     <Stack
  //       direction="row"
  //       alignItems="center"
  //       justifyContent="space-between"
  //       sx={{ typography: 'subtitle2' }}
  //     >
  //       Tags
  //       <IconButton size="small" onClick={toggleTags.onToggle}>
  //         <Iconify
  //           icon={toggleTags.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
  //         />
  //       </IconButton>
  //     </Stack>

  //     {toggleTags.value && (
  //       <Autocomplete
  //         multiple
  //         freeSolo
  //         options={item.tags?.map((option) => option)}
  //         getOptionLabel={(option) => option}
  //         defaultValue={item.tags?.slice(0, 3)}
  //         value={tags}
  //         onChange={(event, newValue) => {
  //           handleChangeTags(newValue);
  //         }}
  //         renderOption={(props, option) => (
  //           <li {...props} key={option}>
  //             {option}
  //           </li>
  //         )}
  //         renderTags={(value, getTagProps) =>
  //           value.map((option, index) => (
  //             <Chip
  //               {...getTagProps({ index })}
  //               size="small"
  //               variant="soft"
  //               label={option}
  //               key={option}
  //             />
  //           ))
  //         }
  //         renderInput={(params) => <TextField {...params} placeholder="#Add a tags" />}
  //       />
  //     )}
  //   </Stack>
  // );

  const renderProperties = (
    <Stack spacing={1.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ typography: 'subtitle2' }}>
        {t('properties')}
        <IconButton size="small" onClick={properties.onToggle}>
          <Iconify icon={properties.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
        </IconButton>
      </Stack>

      {properties.value && (
        <>
          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              {t('size')}
            </Box>
            {fData(size)}
          </Stack>

          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              {t('modified')}
            </Box>
            {format(new Date(modifiedAt || Date.now()), 'dd/MM/yyyy hh:mm')}
          </Stack>

          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              {t('type')}
            </Box>
            {fileFormat(type)}
          </Stack>

          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              {t('pages')}
            </Box>
            {fNumber(item.num_pages) || 0}
          </Stack>

          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              {t('tokens')}
            </Box>
            {fNumber(item.num_words) || 0}
          </Stack>

          {/* <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              Chunk
            </Box>
            {fNumber(item.num_chunks) || 0}
          </Stack> */}

          {/* <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              {t('questions')}
            </Box>
            {fNumber(item.num_questions) || 0}
          </Stack> */}
          {/* 
          <Stack direction="row" sx={{ typography: 'caption', textTransform: 'capitalize' }}>
            <Box component="span" sx={{ width: 140, color: 'text.secondary', mr: 2 }}>
              Risposte chatbot
            </Box>
            {fNumber(item.num_answers) || 0}
          </Stack> */}
        </>
      )}
    </Stack>
  );

  // const renderShared = (
  //   <>
  //     <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
  //       <Typography variant="subtitle2"> File Share With </Typography>

  //       <IconButton
  //         size="small"
  //         color="primary"
  //         onClick={share.onTrue}
  //         sx={{
  //           width: 24,
  //           height: 24,
  //           bgcolor: 'primary.main',
  //           color: 'primary.contrastText',
  //           '&:hover': {
  //             bgcolor: 'primary.dark',
  //           },
  //         }}
  //       >
  //         <Iconify icon="mingcute:add-line" />
  //       </IconButton>
  //     </Stack>

  //     {hasShared && (
  //       <Box sx={{ pl: 2.5, pr: 1 }}>
  //         {shared.map((person) => (
  //           <FileManagerInvitedItem key={person.id} person={person} />
  //         ))}
  //       </Box>
  //     )}
  //   </>
  // );

  const renderStatus = (
    <Label
      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
      color={(status === 'error' && 'error') || (status === 'processing' && 'warning') || 'success'}
      sx={{ textTransform: 'capitalize' }}
    >
      {status ? sentenceCase(status) : ''}
    </Label>
  );

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        slotprops={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 320 },
        }}
        {...other}
      >
        <Scrollbar sx={{ height: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
            <Typography variant="h6"> Info </Typography>

            <Checkbox color="warning" icon={<Iconify icon="eva:star-outline" />} checkedIcon={<Iconify icon="eva:star-fill" />} checked={favorited} onChange={onFavorite} />
          </Stack>

          <Stack
            spacing={2.5}
            justifyContent="center"
            sx={{
              p: 2.5,
              bgcolor: 'background.neutral',
            }}
          >
            <FileThumbnail imageView file={item} sx={{ width: 64, height: 64 }} imgSx={{ borderRadius: 1 }} />

            <Typography variant="subtitle1" sx={{ wordBreak: 'break-all' }}>
              {name}
            </Typography>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {/* {renderTags} */}

            {renderProperties}

            {renderStatus}
          </Stack>

          {/* {renderShared} */}
        </Scrollbar>

        <Box sx={{ p: 2.5 }}>
          <Button fullWidth variant="soft" color="error" size="large" startIcon={<Iconify icon="solar:trash-bin-trash-bold" />} onClick={onDelete}>
            {t('delete')}
          </Button>
        </Box>
      </Drawer>

      {/* <FileManagerShareDialog
        open={share.value}
        shared={shared}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onCopyLink={onCopyLink}
        onClose={() => {
          share.onFalse();
          setInviteEmail('');
        }}
      /> */}
    </>
  );
}

FileManagerFileDetails.propTypes = {
  favorited: PropTypes.bool,
  item: PropTypes.object,
  onClose: PropTypes.func,
  onCopyLink: PropTypes.func,
  onDelete: PropTypes.func,
  onFavorite: PropTypes.func,
  open: PropTypes.bool,
};
