//
import { Typography } from '@mui/material';
// @mui
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
// components
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import CustomPopover, { usePopover } from '../../../components/custom-popover';
import FileThumbnail from '../../../components/file-thumbnail';
import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
// hooks
import { useBoolean } from '../../../hooks/use-boolean';
import { useCopyToClipboard } from '../../../hooks/use-copy-to-clipboard';
import { useResponsive } from '../../../hooks/use-responsive';
import useLocales from '../../../hooks/useLocales';
// utils
import { fData } from '../../../utils/format-number';
import { fNumber } from '../../../utils/formatNumber';
import { fDateTime } from '../../../utils/formatTime';
import FileManagerFileDetails from './file-manager-file-details';
import FileManagerShareDialog from './file-manager-share-dialog';

export default function FileRecentItem({ file, onDelete, sx, ...other }) {
  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const { copy } = useCopyToClipboard();

  const smUp = useResponsive('up', 'sm');

  const [inviteEmail, setInviteEmail] = useState('');

  const popover = usePopover();

  const share = useBoolean();

  const details = useBoolean();

  let { key, id, url, name, size, created_at, modified_at, status, num_chunks, last_chunk_processed, shared } = file;

  const [remove_questions, setRemoveQuestions] = useState(false);

  let successfullChunks = last_chunk_processed + 1;

  let processingChunks = num_chunks - successfullChunks;

  const failedChunks = num_chunks === 0 && last_chunk_processed === 0 && processingChunks === -1 ? 0 : 0;

  if (failedChunks === 1) {
    num_chunks = 1;
    successfullChunks = 0;
    processingChunks = 0;
  }

  const handleChangeInvite = useCallback((event) => {
    setInviteEmail(event.target.value);
  }, []);

  const handleCopy = useCallback(() => {
    enqueueSnackbar('Copied!');
    copy(url);
  }, [copy, enqueueSnackbar, url]);

  const handleRemoveQuestionsChange = (event) => {
    setRemoveQuestions(event.target.checked);
    file.remove_questions = event.target.checked;
  };

  const renderAction = (
    <Box
      sx={{
        top: 0,
        right: 8,
        position: 'absolute',
        ...(smUp && {
          flexShrink: 0,
          position: 'unset',
        }),
      }}
    >
      <Tooltip title="Remove AI Generated Questions">
        <Checkbox color="error" icon={<Iconify icon="bx:bot" />} checkedIcon={<Iconify icon="bx:bot" />} checked={remove_questions} onChange={handleRemoveQuestionsChange} />
      </Tooltip>
      <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
        <Iconify icon="eva:more-vertical-fill" sx={undefined} />
      </IconButton>
    </Box>
  );

  const renderTextWithTooltip = (
    <>
      <a data-tooltip-id="file-name" data-tooltip-content={name} href="/">
        <Typography variant="body2">{name?.length > 31 ? `${name.substring(0, 31)}...` : name}</Typography>
      </a>
      <ReactTooltip id="file-name" />
    </>
  );

  const renderText = (
    <ListItemText
      key={key}
      onClick={details.onTrue}
      primary={renderTextWithTooltip}
      secondary={
        <>
          {fData(size)}
          <Box
            sx={{
              mx: 0.75,
              width: 2,
              height: 2,
              borderRadius: '50%',
              bgcolor: 'currentColor',
            }}
          />
          {fDateTime(new Date(created_at))}
        </>
      }
      primaryTypographyProps={{
        noWrap: true,
        typography: 'subtitle2',
      }}
      secondaryTypographyProps={{
        mt: 0.5,
        component: 'span',
        alignItems: 'center',
        typography: 'caption',
        color: 'text.disabled',
        display: 'inline-flex',
      }}
    />
  );

  const renderStatus = (
    <Stack direction="row" spacing={0.5}>
      <>
        <Label variant="ghost" color={'info'}>
          {' '}
          Total {fNumber(num_chunks)}{' '}
        </Label>
        <Label variant="ghost" color={'success'}>
          {' '}
          Success {fNumber(successfullChunks)}{' '}
        </Label>
        <Label variant="ghost" color={'warning'}>
          {' '}
          Processing {fNumber(processingChunks)}{' '}
        </Label>
        <Label variant="ghost" color={'error'}>
          {' '}
          Failed {fNumber(failedChunks)}{' '}
        </Label>
      </>
    </Stack>
  );

  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        spacing={1}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'unset', sm: 'center' }}
        sx={{
          borderRadius: 2,
          bgcolor: 'unset',
          cursor: 'pointer',
          position: 'relative',
          p: { xs: 2.5, sm: 2 },
          '&:hover': {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          },
          ...sx,
        }}
        {...other}
      >
        <FileThumbnail file={file} sx={{ width: 36, height: 36, mr: 1 }} />

        {renderText}

        {file?.id ? (
          <ListItemText
            primary={renderStatus}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        ) : (
          <></>
        )}

        {renderAction}
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 180 }}>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={() => {
            popover.onClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('delete')}
        </MenuItem>
      </CustomPopover>

      <FileManagerFileDetails
        item={file}
        favorited={remove_questions}
        onFavorite={handleRemoveQuestionsChange}
        onCopyLink={handleCopy}
        open={details.value}
        onClose={details.onFalse}
        onDelete={() => {
          details.onFalse();
          onDelete();
        }}
      />

      <FileManagerShareDialog
        open={share.value}
        shared={shared}
        inviteEmail={inviteEmail}
        onChangeInvite={handleChangeInvite}
        onCopyLink={handleCopy}
        onClose={() => {
          share.onFalse();
          setInviteEmail('');
        }}
      />
    </>
  );
}

FileRecentItem.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDelete: PropTypes.func,
  sx: PropTypes.object,
};
