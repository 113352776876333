import { verifyPasswordResetCode } from 'firebase/auth';
import { useSnackbar } from 'notistack';
// hooks
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'src/redux/store';

// components
import LoadingScreen from '../components/LoadingScreen';
import { AUTH } from '../contexts/FirebaseContext';
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// import { useIntercom } from 'react-use-intercom';
import { PATH_AUTH } from '../routes/paths';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  // const { update } = useIntercom();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname, search } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const { account } = useSelector((state) => state.account);

  // const hasActivePlan = account && account.hasActivePlan;
  const hasActivePlan = account && true;

  const params = new URLSearchParams(search);
  const mode = params.get('mode');
  const actionCode = params.get('oobCode');

  const handleVerifyPasswordResetCode = async (actionCode) => {
    try {
      await verifyPasswordResetCode(AUTH, actionCode)
        .then((email) => {
          const accountEmail = email;
          if (accountEmail) {
            sessionStorage.setItem('email-recovery', accountEmail);
            navigate(PATH_AUTH.newPassword + search);
          } else {
            sessionStorage.removeItem('email-recovery');
            enqueueSnackbar('Invalid code', { variant: 'error' });
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('Invalid code!\n' + error.message, {
            variant: 'error',
          });
        });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Invalid code!\n' + error.message, { variant: 'error' });
    }
  };

  if (!isInitialized) {
    return <LoadingScreen isDashboard={pathname.includes('/dashboard')} />;
  }

  if (isAuthenticated && !hasActivePlan && actionCode === null) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    //ToDO: Remove this line and uncomment the return statement below
    // return <Navigate to={PATH_DASHBOARD.root} />;
    // return <EcommerceCheckout />;
  }

  if (!isAuthenticated && mode !== null && actionCode !== null) {
    switch (mode) {
      case 'resetPassword':
        handleVerifyPasswordResetCode(actionCode);
        break;
      case 'recoverEmail':
        // handleRecoverEmail(auth, actionCode, lang);
        break;
      case 'verifyEmail':
        // handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
        if (pathname !== requestedLocation) {
          setRequestedLocation(pathname);
        }
        return <Login />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (user && user?.displayName && user?.email) {
    // update({ name: user?.displayName, email: user?.email }); Intercom
    posthog?.identify(user?.uid, {
      email: user?.email,
      name: user?.displayName,
    });
  }

  return <>{children}</>;
}
