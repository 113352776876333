import { createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

const { REACT_APP_SOFIA_API } = process.env;

const initialState = {
  isLoading: false,
  error: null,
  questions: [],
  isDeleting: false,
  isBatchCreating: false,
  isAsking: false,
  operationProgress: 0,
};

const slice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    startAsking(state) {
      state.isAsking = true;
      state.operationProgress = 0;
    },
    updateAskingProgress(state, action) {
      state.operationProgress = action.payload;
    },
    receiveGPTAnswer(state, action) {
      state.isAsking = false;
      state.operationProgress = 100;
      const currentQuestion = state.questions.find((q) => q.id === action.payload.id);
      if (currentQuestion) {
        currentQuestion.answer = action.payload.answer;
      }
    },
    getQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload.questions;
    },
    editQuestionSuccess(state, action) {
      state.isLoading = false;
      const editedQuestion = action.payload.question;
      const index = state.questions.findIndex((q) => q.id === editedQuestion.id);
      if (index !== -1) {
        state.questions[index] = editedQuestion;
      }
    },
    startDeleting(state) {
      state.isDeleting = true;
      state.operationProgress = 0;
    },
    updateDeletingProgress(state, action) {
      state.operationProgress = action.payload;
    },
    deleteQuestionSuccess(state, action) {
      state.isDeleting = false;
      state.questions = state.questions.filter((q) => !action.payload.questionIds.includes(q.id));
      state.operationProgress = 100;
    },
    addQuestionSuccess(state, action) {
      state.isLoading = false;
      state.questions.push(action.payload.question);
    },
    startBatchCreating(state) {
      state.isBatchCreating = true;
      state.operationProgress = 0;
    },
    updateBatchCreatingProgress(state, action) {
      state.operationProgress = action.payload;
    },
    batchCreateQuestionsSuccess(state, action) {
      state.isBatchCreating = false;
      state.questions = [...state.questions, ...action.payload.questions];
      state.operationProgress = 100;
    },
  },
});

export const {
  startLoading,
  hasError,
  startAsking,
  updateAskingProgress,
  receiveGPTAnswer,
  getQuestionsSuccess,
  editQuestionSuccess,
  deleteQuestionSuccess,
  addQuestionSuccess,
  batchCreateQuestionsSuccess,
  startDeleting,
  startBatchCreating,
  updateDeletingProgress,
  updateBatchCreatingProgress,
} = slice.actions;

export default slice.reducer;

// Helper function to get CurrentProject header value
const getCurrentProject = () => localStorage.getItem('currentProject');

export const askGPT = (questionData) => async (dispatch) => {
  dispatch(startAsking());
  try {
    const conversationId = localStorage.getItem('currentConversationKey');
    const projectId = getCurrentProject();
    const { question: message, id } = questionData;

    // Simulate progress updates
    for (let i = 0; i <= 90; i += 10) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      dispatch(updateAskingProgress(i));
    }

    const response = await axios.post(
      `${REACT_APP_SOFIA_API}/api/chat/ask-gpt`,
      {
        question: message,
        conversation_id: conversationId,
      },
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );

    let { answer } = response.data;
    answer = answer.split('<div class="sources-wrapper">', 1)[0];

    dispatch(receiveGPTAnswer({ id, answer }));
    return { id, answer }; // Return the answer
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to ask GPT.'));
    throw error; // Re-throw the error to be caught in the component
  }
};

export const batchCreateQuestions = () => async (dispatch) => {
  dispatch(startBatchCreating());
  try {
    const projectId = getCurrentProject();

    // Simulate progress updates
    for (let i = 0; i <= 100; i += 10) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      dispatch(updateBatchCreatingProgress(i));
    }

    const response = await axios.post(
      `${REACT_APP_SOFIA_API}/api/question/batch/create`,
      {},
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(batchCreateQuestionsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to batch create questions.'));
  }
};

export const deleteQuestion = (questionIds) => async (dispatch) => {
  dispatch(startDeleting());
  try {
    const projectId = getCurrentProject();

    // Simulate progress updates
    for (let i = 0; i <= 100; i += 10) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      dispatch(updateDeletingProgress(i));
    }

    await axios.delete('/api/question/delete', {
      data: { question_ids: questionIds },
      headers: {
        CurrentProject: projectId,
      },
    });
    dispatch(deleteQuestionSuccess({ questionIds }));
    await dispatch(getQuestions());
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to delete question(s).'));
  }
};

export const getQuestions = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const projectId = getCurrentProject();
    const response = await axios.post(
      `${REACT_APP_SOFIA_API}/api/question/list`,
      {},
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(getQuestionsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to fetch questions.'));
  }
};

export const editQuestion = (question) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const projectId = getCurrentProject();
    const { data } = await axios.put(
      '/api/question/edit',
      { question },
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(editQuestionSuccess(data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to edit question.'));
  }
};

export const addQuestion = (question) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const projectId = getCurrentProject();
    const response = await axios.post(
      '/api/question/add',
      { question },
      {
        headers: {
          CurrentProject: projectId,
        },
      }
    );
    dispatch(addQuestionSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Failed to add question.'));
  }
};
