import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';

import axios from '../../utils/axios';
import { getProjectFromFirestore, getProjectKeysFromFirestore, getProjectsFromFirestore, saveProjectKeysInFirestore, saveProjectToFirestore } from '../../utils/firestore';
import { dispatch } from '../store';

const { REACT_APP_SOFIA_API } = process.env;

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  sortBy: null,
  currentProjectKeys: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: [0, 200],
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    removeFileSuccess(state, action) {
      state.products = action.payload;
    },

    createProductSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    saveProjectSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // @ts-ignore
    // @ts-ignore
    saveDefaultProjectSuccess(state, action) {
      state.isLoading = false;
    },

    fileUploadStarted(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    fileUploadSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    deleteProductSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    deleteProjectSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    deleteFileSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      // throw new Error('reducer must be removed');
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    saveProjectKeysSuccess(state, action) {
      state.isLoading = false;
      const { projectId, keys } = action.payload;
      state.products = state.products.map((project) => (project.id === projectId ? { ...project, keys } : project));
      state.currentProjectKeys = keys;
    },

    fetchProjectKeysSuccess(state, action) {
      state.isLoading = false;
      state.currentProjectKeys = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  removeFileSuccess,
  fetchProjectKeysSuccess,
} = slice.actions;

export function getProjects() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const projects = await getProjectsFromFirestore(uid);
      dispatch(slice.actions.getProductsSuccess(projects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchProject(text) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const projects = await getProjectsFromFirestore(uid);
      const filteredProjects = projects.filter((project) => project.name.toLowerCase().includes(text.toLowerCase()));
      dispatch(slice.actions.getProductSuccess(filteredProjects));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveProjectKeys({ project, keys }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');

      // Save keys in the project's keys collection
      const updatedKeys = await saveProjectKeysInFirestore(uid, project.id, keys);

      dispatch(slice.actions.saveProjectKeysSuccess({ projectId: project.id, keys: updatedKeys }));
    } catch (error) {
      console.log('There was an error saving the project keys', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function fetchProjectKeys(projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const keys = await getProjectKeysFromFirestore(uid, projectId);
      dispatch(slice.actions.fetchProjectKeysSuccess(keys));
    } catch (error) {
      console.log('There was an error fetching the project keys', error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function saveProject(data) {
  return async () => {
    try {
      const uid = localStorage.getItem('accountUid');

      await saveProjectToFirestore(uid, data);

      const projects = await getProjectsFromFirestore(uid);

      dispatch(slice.actions.getProductsSuccess(projects));
    } catch (error) {
      console.log('There was an error saving the project', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProject(projectsToDelete) {
  return async () => {
    const uid = localStorage.getItem('accountUid');
    const projectId = projectsToDelete[0].id;
    const documentIds = projectsToDelete[0].files.map((file) => file?.id ?? 0);
    try {
      const additionalHeaders = {
        CurrentProject: `${projectId}`,
      };
      const headers = {
        ...axios.defaults.headers.common,
        ...additionalHeaders,
      };

      const URL = `${REACT_APP_SOFIA_API}/api/project/delete`;

      dispatch(slice.actions.startLoading());

      const result = await axios.delete(URL, {
        headers: headers,
        data: {
          ids: documentIds,
          uid,
          filter: {
            chain: projectId,
          },
        },
        timeout: 0,
      });

      dispatch(slice.actions.deleteProjectSuccess(result.data.products));
    } catch (error) {
      console.log('There was an error deleting the project', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteFiles(projectId, remainingFiles, deleteQuestions) {
  return async () => {
    const uid = localStorage.getItem('accountUid');
    let idsToDelete = [];
    let savedFiles = [];
    try {
      const idsToHold = remainingFiles.map((file) => file?.id ?? 0);

      const project = await getProjectFromFirestore(uid, projectId);
      if (project) {
        // @ts-ignore
        savedFiles = project.files.length > 0 ? project.files : [];
        idsToDelete = savedFiles.filter((file) => !idsToHold.includes(file.id)).map((file) => file?.id ?? 0);
      }
      console.log('shouldDeleteQuestions', deleteQuestions);
      const additionalHeaders = {
        CurrentProject: `${projectId}`,
      };
      const headers = {
        ...axios.defaults.headers.common,
        ...additionalHeaders,
      };
      const URL = `${REACT_APP_SOFIA_API}/api/project/files/delete`;
      dispatch(slice.actions.startLoading());
      if (idsToDelete.length === 0) {
        alert('No ids to delete');
        return;
      }
      const response = await axios.delete(URL, {
        headers: headers,
        data: {
          ids: idsToDelete,
          deleteQuestions: deleteQuestions,
          filter: {
            chain: projectId,
          },
        },
      });
      dispatch(slice.actions.deleteFileSuccess(response.data.products));
    } catch (error) {
      console.log('There was an error deleting the files', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
