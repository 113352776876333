import { createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../../utils/axios';

const { REACT_APP_SOFIA_API } = process.env;

const initialState = {
  isLoading: false,
  error: null,
  voices: [],
  isStreaming: false,
  isStreamingLoading: false,
  streamingError: null,
  audio: null,
};

const voiceSlice = createSlice({
  name: 'voice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    listVoicesSuccess(state, action) {
      state.isLoading = false;
      state.voices = action.payload;
    },
    onStartStreaming(state) {
      state.isStreamingLoading = true;
      state.isStreaming = false;
      state.streamingError = null;
    },
    onHasErrorStreaming(state, action) {
      state.isStreamingLoading = false;
      state.isStreaming = false;
      state.streamingError = action.payload;
    },
    onPlayStreaming(state, action) {
      state.isStreamingLoading = false;
      state.isStreaming = true;
      state.audio = new Audio(URL.createObjectURL(action.payload));
    },
    onStreamingEnded(state) {
      state.isStreaming = false;
      state.isStreamingLoading = false;
    },
  },
});

export default voiceSlice.reducer;

export const { startLoading, hasError, listVoicesSuccess, onStartStreaming, onHasErrorStreaming, onPlayStreaming, onStreamingEnded } = voiceSlice.actions;

// Async actions

export function listVoices() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const projectId = localStorage.getItem('currentProject');

      const URL = `${REACT_APP_SOFIA_API}/api/voice/list`;

      const response = await axiosInstance.post(
        URL,
        {},
        {
          headers: {
            ...axiosInstance.defaults.headers.common,
            CurrentProject: `${projectId}`,
          },
        }
      );
      dispatch(listVoicesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function startVoiceStreaming(message, voiceId) {
  return async (dispatch, getState) => {
    dispatch(onStartStreaming());
    try {
      const text = message.body;
      await axiosInstance;
      const projectId = localStorage.getItem('currentProject');
      const URL = `${REACT_APP_SOFIA_API}/api/voice/stream`;
      await axiosInstance
        .post(
          URL,
          {
            text,
            voiceId,
          },
          {
            headers: {
              ...axiosInstance.defaults.headers.common,
              CurrentProject: `${projectId}`,
            },
            responseType: 'blob',
          }
        )
        .then((response) => {
          dispatch(onPlayStreaming(response.data));

          const currentState = getState();
          const { audio } = currentState.voice;

          audio.addEventListener('ended', () => {
            dispatch(onStreamingEnded());
          });

          audio.play();
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      dispatch(onHasErrorStreaming(error));
    }
  };
}
