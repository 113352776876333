const it = {
  demo: {
    title: `Italiano`,
    introduction: `Introduzione`,
  },
  docs: {
    hi: `Ciao`,
    description: `Hi bisogno di aiuto? \n Contatta il nostro`,
    documentation: `team!`,
  },
  sofia_your_business_oracle: `sοφία, il tuo Oracolo Aziendale`,
  enhance_your_knowledge_base: `Potenzia la tua Knowledge Base! Interroga documenti e dati aziendali con la potenza di ChatGPT`,
  export_conversation: `Export Conversazione`,
  dashboard: `Dashboard`,
  app: `app`,
  user: `Utente`,
  list: `elenco`,
  edit: `modifica`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `e-mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `creare`,
  kanban: `kanban`,
  general: `generale`,
  banking: `banca`,
  booking: `prenotazione`,
  profile: `profilo`,
  account: `account`,
  product: `prodotto`,
  invoice: `fattura`,
  details: `dettagli`,
  checkout: `cassa`,
  calendar: `calendario`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `progetti`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `Descrizione`,
  other_cases: `altri casi`,
  item_by_roles: `voce per ruoli`,
  only_admin_can_see_this_item: `Solo l'amministratore può vedere questa voce`,
  name: `Nome`,
  created_at: `Creato il`,
  updated_at: `Aggiornato il`,
  size: `Dimensione`,
  type: `Tipo`,
  status: `Stato`,
  file: `File`,
  upload: `Carica file`,
  download: `Scarica file`,
  delete: `Elimina`,
  copy_link: `Copia link`,
  remove_all: `Rimuovi tutti`,
  go_back: `Torna indietro`,
  not_found: '0 Risultati',
  no_results_found_for: 'Nessun risultato trovato per',
  try_checking_for_typos_or_using_complete_words: 'Prova a controllare gli errori di battitura o ad utilizzare parole diverse',
  please_enter_keywords: 'Inserisci le parole chiave',
  delete_conversation: `Elimina conversazione`,
  tts_voice_test: `Ciao, sono il tuo assistente virtuale. Come posso aiutarti?`,
  chats: {
    title: `Conversazioni`,
    search_contacts: `Cerca conversazioni...`,
    recipients: `Bots`,
    send_to: `Invia a`,
    type_message: `Scrivi un messaggio...`,
    search_available_questions: `Cerca tra le domande generate da Sofia...`,
    how_can_i_help_you: `Ciao, come posso aiutarti?`,
    conversations: `Conversazioni`,
    create_new_conversation_on_project: `Nuova conversazione`,
    new_conversation_created_successfully: `Nuova conversazione creata con successo`,
    new_conversation_creation_failed: `Creazione nuova conversazione non riuscita`,
  },
  users: {
    accounts: {
      settings: `Impostazioni`,
    },
  },
  files: {
    exclude_from_indexes: `Escludi dagli indici`,
    drop_or_select_files: `Trascina o seleziona i file`,
    drop_files_here_or_click: `Trascina i file qui o clicca`,
    browse: `apri`,
    through_your_machine: `dal tuo computer`,
    upload_files: `Carica file`,
    search_files: `Cerca...`,
    drop_files_here_to_upload: `Trascina qui i file per caricarli`,
    files_size: `Dimensioni file`,
    files_progress: `Progresso file`,
    no_files: `Nessun file`,
    delete: `Elimina file`,
    preview: `Anteprima file`,
    failed: `File non riusciti`,
    deleted: `File eliminati`,
    approved: `File approvati`,
    rejected: `File rifiutati`,
    pending: `File in attesa`,
    processed: `File elaborati`,
    uploading: `Caricamento file`,
    limit: `Limite file`,
    cancelled: `File annullati`,
    type_not_allowed: `Tipo di file non consentito`,
    max_size: `Dimensione massima dei file`,
    max_files: `Numero massimo di file`,
    required: `E' necessario caricare almeno un file`,
    uploaded_successfully: `File caricati con successo`,
    upload_started: `Caricamento file iniziato`,
    upload_failed: `Caricamento file non riuscito`,
    upload_cancelled: `Caricamento file annullato`,
    deleted_successfully: `File eliminati con successo`,
    deletion_started: `Eliminazione file in corso`,
    deletetion_failed: `Eliminazione file non riuscita`,
  },
  projects: {
    save: `Salva il progetto`,
    saved_successfully: `Progetto salvato con successo`,
    save_failed: `Salvataggio progetto non riuscito`,
    delete: `Elimina progetto`,
    deleted_successfully: `Progetto eliminato con successo`,
    delete_failed: `Eliminazione progetto non riuscita`,
    create: `Crea progetto`,
    is_active: `Progetto attivo`,
    projects_status: `Stato progetti`,
    projects_available: `Progetti disponibili`,
    projects_used: `Progetti utilizzati`,
    project_name_is_required: `Il nome del progetto è obbligatorio`,
    project_description_is_required: `La descrizione del progetto è obbligatoria`,
    use_with_chatgpt_plugin: `Usa con il plugin ChatGPT`,
    questions_master_prompt: `Prompt principale domande`,
    answers_master_prompt: `Prompt principale risposte`,
    query_top_k: `Top K query risposte`,
    chunk_questions: `N° domande per chunk`,
    chunks: `Chunks`,
    chunk_size: `Dimensione chunk`,
    chunk_overlap: `Sovrapposizione chunk`,
    temperature_questions: `Temperatura generazione domande`,
    temperature_answers: `Temperatura risposte chatbot`,
    max_tokens_answers: `N° token massimi per risposta`,
    model_name_answers: `Modello risposte chatbot`,
    model_name_questions: `Modello generazione domande`,
    embedding_model: `Modello embedding`,
    hybrid_convex_alpha: `Hybrid Convex Scale Alpha`,
    qa_language: `Lingua del progetto`,
    tts_settings: `Impostazioni Sinthesi Vocale`,
    voice_for_questions: `Voce per domande`,
    voice_for_answers: `Voce per risposte`,
    conversation_deleted_successfully: `Conversazione eliminata con successo`,
    delete_conversation_failed: `Eliminazione conversazione non riuscita`,
    telephony_app_url: `URL App Telefonia`,
    voice_app_url: `URL App Voce`,
    index_has_vectors: `L'indice ha vettori`,
    index_has_no_vectors: `L'indice non ha vettori`,
    error_checking_vectors: `Errore durante il controllo dei vettori`,
  },
  login: {
    dont_have_an_account: `Non hai un account?`,
    sign_up: `Iscriviti`,
    already_have_an_account: `Hai già un account?`,
    log_in: `Accedi`,
    reset_password: `Reimposta la password`,
    forgot_password: `Hai dimenticato la password?`,
    email_address: `Indirizzo e-mail`,
    password: `Password`,
    get_started: `Inizia ora`,
    loading: `Caricamento...`,
    hi_welcome_back: `Ciao, `,
    enter_your_details_below: `Inserisci i tuoi dati qui sotto.`,
    use_email: `Utilizza l'e-mail :`,
    dont_have_an_account_sign_up: `Non hai un account? Iscriviti`,
    by_clicking_sign_up_you_agree_to: `Clicckando su "Iscriviti", accetti le nostre`,
    our_terms_of_service_and_privacy_policy: `Condizioni di servizio e privacy policy`,
    welcome_to: `Ciao`,
    sign_in: `Accedi`,
    login_to_your_account: `Accedi al tuo account`,
    request_sent_success: `Richiesta inviata con successo`,
    remember_me: `Ricordami`,
    log_in_with_google: `Continua con Google`,
    or: `oppure`,
  },
  register: {
    title: `Registrati`,
    already_have_an_account: `Hai già un account?`,
    log_in: `Log in`,
    manage_the_job_more_effectively_with: `Gestisci il lavoro in modo più efficace con`,
    get_started_absolutely_free: `Inizia il periodo di prova gratuito`,
    free_forever_no_credit_card_needed: `Sofia GPT v1.3.0`,
    by_registering_i_agree_to_our: `Registrandoti, accetti i nostri`,
    and: `e`,
    terms_of_service: `Termini di servizio`,
    already_have_an_account_login: `Hai già un account? Log in`,
    password_rules: `La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero e un simbolo.`,
  },
  new_password: {
    title: `Nuova password`,
    request_sent_successfully: `Richiesta inviata con successo!`,
    weve_sent_a_6_digit_confirmation_email_to_your_email: `Abbiamo inviato un'e-mail di conferma a 6 cifre al tuo indirizzo e-mail.`,
    please_enter_the_code_in_below_box_to_verify_your_email: `Inserisci il codice nella casella sottostante per verificare la tua e-mail.`,
    dont_have_a_code: `Non hai un codice?`,
    resend_code: `Rispedisci il codice`,
    code_is_required: `Il codice è obbligatorio`,
    code_must_be_a_6_digit_number: `Il codice deve essere un numero di 6 cifre`,
  },
  reset_password: {
    title: `Reimposta la password`,
    forgot_your_password: `Hai dimenticato la password?`,
    please_enter_the_email_address_associated_with_your_account: `Inserisci l'indirizzo e-mail associato al tuo account e ti invieremo un link per reimpostare la password.`,
    back: `Indietro`,
  },
  nav_items: {
    dashboard: `Dashboard`,
    management: `Gestione`,
    users: `Utenti`,
    user: `Utente`,
    account: `Account`,
    settings: `Impostazioni`,
    logout: `Logout`,
    login: `Login`,
    register: `Registrati`,
    new_password: `Nuova password`,
    reset_password: `Reimposta la password`,
    profile: `Profilo`,
    edit_profile: `Modifica profilo`,
    change_password: `Cambia password`,
    user_management: `Gestione utenti`,
    user_list: `Lista utenti`,
    user_edit: `Modifica utente`,
    user_create: `Crea utente`,
    user_detail: `Dettaglio utente`,
    user_delete: `Elimina utente`,
    chat: `Chat`,
    mail: `Mail`,
    subscription: `Abbonamento`,
    files: `Files`,
    invoices: `Fatture`,
    projects: `Progetti`,
    questions: `Domande`,
    conversations: `Conversazioni`,
  },
  breadcrumbs: {
    home: `Home`,
    dashboard: `Dashboard`,
    account: `Account`,
    upload_files: `Carica file`,
    manage_files: `Gestisci file`,
    data: `Dati`,
    projects: `Progetti`,
    choose_your_plan: `Scegli il tuo piano`,
    payment: `Pagamento`,
    checkout: `Checkout`,
    subscription: `Abbonamento`,
    billing_address: `Indirizzo di fatturazione`,
    done: `Completato`,
    new_project: `Nuovo progetto`,
    edit_project: `Modifica`,
    list: `Elenco`,
  },
  current_subscription_info: `Informazioni sul tuo piano corrente`,
  you_are_signed_in_as: `Sei registrato come `,
  check_your_subscribed_plan_here: `Controlla il tuo piano qui`,
  settings: `Impostazioni`,
  your_plan_status_is: `Lo stato del tuo piano è`,
  active: `Attivo`,
  expired: `Scaduto`,
  inactive: `Inattivo`,
  current_plan: `Piano corrente`,
  plan: `Piano`,
  price: `Prezzo`,
  next_payment: `Prossimo pagamento`,
  properties: `Proprietà`,
  modified: `Modificato`,
  subscribe: `Abbonati`,
  buy_now: `Acquista ora`,
  start_free_trial: `Inizia la prova gratuita`,
  cancel_subscription: `Annulla abbonamento`,
  your_current_plan: `Il tuo piano corrente`,
  plan_activated_successfully: `Piano attivato con successo`,
  plan_deactivated_successfully: `Piano disattivato con successo`,
  plan_activation_failed: `Attivazione piano non riuscita`,
  checkout_process_started_plase_wait: `Processo di acquisto avviato. Attendere prego...`,
  popular: `Opzione consigliata`,
  save_changes: `Salva modifiche`,
  updgrade_plan: `Effettua upgrade`,
  available: `Disponibili`,
  used: `Utilizzati`,
  used_f: `Utilizzate`,
  pages: `Pagine`,
  words: `Parole`,
  queries: `Domande`,
  answers: `Risposte`,
  included_in_plan: `Inclusi nel piano`,
  included_in_plan_f: `Incluse nel piano`,
  plan_limit_reached: `Limite piano raggiunto`,
  number_of_projects_limit_reached: `Non puoi creare altri progetti \n ma puoi caricare file e porre domande`,
  welcome_back: `Bentornato \n`,
  upgrade_your_plan_to_create_more_projects: `Clicca sul progetto per caricare i tuoi files oppure Effettua l'upgrade per creare altri progetti.`,
  create_new_projects_upload_your_files_and_ask_questions_to_sofia_gpt: `Crea nuovi progetti, carica i tuoi file e fai domande a Sofia GPT`,
  messages: `Messaggi`,
  ai_faqs: `FAQs IA`,
  bot_answers: `Risposte bot`,
  no_files_uploaded: `Carica files`,
  downgrade_plan: `Effettua downgrade`,
  email_must_be_a_valid_email_address: `L'email deve essere un indirizzo email valido`,
  email_is_required: `L'email è obbligatoria`,
  password_is_required: `La password è obbligatoria`,
  first_name_is_required: `Il nome è obbligatorio`,
  last_name_is_required: `Il cognome è obbligatorio`,
  email_address: `Indirizzo e-mail`,
  first_name: `Nome`,
  last_name: `Cognome`,
  password: `Password`,
  this_email_is_invalid: `L'email non è valida`,
  this_account_has_been_deactivated_or_deleted: `L'account è stato disattivato o eliminato`,
  this_user_was_not_found_have_you_created_your_account: `L'utente non è stato trovato: hai creato il tuo account?`,
  this_email_was_already_used_for_another_account: `Questa email è già stata utilizzata per un altro account`,
  service_seems_to_be_unavailable_please_try_again_later: `Il servizio sembra non essere disponibile, riprova più tardi`,
  please_log_out_and_log_in_again_to_verify_your_identify_then_change_your_email: `Disconnettiti e accedi nuovamente per verificare la tua identità, quindi cambia la tua email`,
  that_s_a_lot_of_unsuccessful_login_attempts_wait_a_bit_before_trying_again_or_reset_your_password: `Troppi tentativi di accesso non riusciti. Attendi un po' prima di riprovare o reimposta la tua password`,
  the_password_is_invalid: `La password non è valida`,
  this_password_is_too_weak_please_use_more_letters_and_digits: `La password è troppo debole, utilizza più lettere e numeri`,
  confirm_password_is_required: `La conferma della password è obbligatoria`,
  password_does_not_match: `La password e la conferma della password non corrispondono`,
  confirm_password: `Conferma password`,
  available_only_on_enterprise_plan: `Disponibile solo con il piano Enterprise`,
  refresh_token: `Aggiorna token`,
  token_updated_successfully: `Token aggiornato con successo`,
  token_update_failed: `Aggiornamento token non riuscito`,
  generate_token: `Genera token`,
  tokens: `Tokens`,
  questions_menu: `Domande`,
  questions: {
    list_title: `FAQ: Elenco`,
    list_heading: `Elenco FAQ`,
    chat_name: `Chat`,
    faqs_name: `FAQ`,
    new_question_action: `Nuova Domanda`,
    label_question: `Domanda`,
    label_questions: `Domande`,
    label_updated_at: `Data Agg.`,
    label_topic: `Argomento`,
    start_date: `Data di inizio`,
    end_date: `Data di fine`,
    search_placeholder: `Cerca domanda o argomento...`,
    delete: `Elimina`,
    edit: `Modifica`,
    required: `La domanda è obbligatoria`,
    updated_successfully: `FAQ salvata con successo`,
    update_failed: `Salvataggio non riuscito`,
    deleted_successfully: `FAQ eliminata con successo`,
    delete_failed: `Eliminazione non riuscita`,
    asked_successfully: `Generazione risposta in corso...attendere prego`,
    ask_failed: `Si è verificato un errore durante la generazione della risposta`,
    label_answer: `Risposta`,
    save: `Salva`,
    generate_answer: `Genera risposta automatica`,
    export_to_csv: `Esporta in CSV`,
    export_to_excel: `Esporta in Excel`,
    faqs_deletion_started: `Eliminazione FAQ in corso.`,
    faqs_deletion_successfully: `FAQs eliminate con successo`,
    faqs_deletetion_failed: `Eliminazione FAQs non riuscita`,
    faqs_batch_create_started: `Creazione batch FAQs in corso. Torna più tardi per vedere i risultati.`,
    faqs_batch_create_successfully: `FAQs batch create success`,
    faqs_batch_create_failed: `Creazione batch FAQs non riuscita`,
    faqs_batch_create_answers_started: 'Creazione batch risposte in corso. Torna più tardi per vedere i risultati.',
    faqs_batch_create_answers_successfully: 'Creazione batch risposte riuscita',
    faqs_batch_create_answers_failed: 'Creazione batch risposte non riuscita',
    no_questions_selected: 'Nessuna domanda selezionata',
  },
  phone_number: `Numero di telefono`,
  address: `Indirizzo`,
  country: `Paese`,
  state: `Provincia`,
  city: `Città`,
  zip_code: `CAP`,
  about: `Informazioni`,
};

export default it;
