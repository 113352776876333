// routes

import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
// components
import ThemeSettings from './components/settings';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// import { IntercomProvider } from 'react-use-intercom';
// import useGTM from '@elgorditosalsero/react-gtm-hook'

// const INTERCOM_APP_ID = 'gtqmyzm8';
export default function App() {
  // const { init } = useGTM()
  // useEffect(() => init({ id: 'GTM-5SRL7L8Q' }), [])
  // const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  // const onHide = () => {
  //   localStorage.setItem('show_intercom_on_refresh', 'false');
  // }
  // const onUnreadCountChange = (amount) => {
  //   setUnreadMessagesCount(amount);
  // };
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
// return (
//   <IntercomProvider
//     appId={INTERCOM_APP_ID}
//     onHide={onHide}
//     autoBoot={true}
//     >
//     <MotionLazyContainer>
//       <ThemeProvider>
//         <ThemeSettings>
//           <NotistackProvider>
//             <ProgressBarStyle />
//             <ChartStyle />
//             <ScrollToTop />
//             <Router />
//           </NotistackProvider>
//         </ThemeSettings>
//       </ThemeProvider>
//     </MotionLazyContainer>
//   </IntercomProvider>
// );
