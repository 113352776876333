// routes
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  // conversation: getIcon('ic_transfer_list'),
  // mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  // kanban: getIcon('ic_kanban'),
  // banking: getIcon('ic_banking'),
  // booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  // calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  {
    key: 'dashboard',
    subheader: 'dashboard',
    items: [
      {
        key: 'projects',
        title: 'projects',
        path: PATH_DASHBOARD.project.list,
        icon: ICONS.invoice,
      },
      // {
      //   key: 'projects',
      //   title: 'projects',
      //   path: PATH_DASHBOARD.project.shop,
      //   icon: ICONS.invoice,
      // },

      {
        key: 'chat',
        title: 'chat',
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
        children: [
          {
            key: 'conversations',
            title: 'conversations',
            path: PATH_DASHBOARD.chat.root,
          },
          {
            hiddenOnMobile: true,
            key: 'questions',
            title: 'questions',
            path: PATH_DASHBOARD.questions.list,
          },
        ],
      },
      {
        key: 'account',
        title: 'account',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          {
            key: 'user',
            title: 'user',
            path: PATH_DASHBOARD.user.account,
          },
          {
            key: 'subscription',
            title: 'subscription',
            path: PATH_DASHBOARD.user.checkout,
          },
        ],
      },
    ],
  },
];

export default navConfig;
