import { createSlice } from '@reduxjs/toolkit';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc } from 'firebase/firestore';

import { DB } from '../../contexts/FirebaseContext';
//
import { dispatch } from '../store';

// Initial state for user account slice

const initialState = {
  isLoading: false,
  error: null,
  account: null,
};

// Create a slice for user account slice

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SET ACCOUNT TO CURRENT USER
    setAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    },
    // UPDATE SHOPIFYT API KEYS
    upsertApiKeysSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    },
    resetApiKeys(state) {
      state.account.apiKeys = null;
    },
    getAccountSubscriptionSuccess(state, action) {
      state.isLoading = false;
      const user = action?.payload;
      const subscriptions = action.payload?.subscriptions;
      const currentSubscription = subscriptions[0];
      const currentplan = currentSubscription?.items[0]?.plan;
      const planMetadata = currentSubscription?.items[0]?.price?.product?.metadata;

      state.account = state.account || action.payload;
      // state.account = action.payload;

      state.account.subscriptions = subscriptions;
      state.account.plan = currentplan;
      state.account.isPlanActive = currentplan?.active || false;
      state.account.priceId = currentplan?.id || null;
      state.account.hasActivePlan = (currentplan?.id && currentplan?.active) || false;
      state.account.max_num_projects = parseInt(planMetadata?.max_num_conversations) || 1;
      state.account.max_num_conversations = parseInt(planMetadata?.max_num_conversations) || parseInt(planMetadata?.max_num_bots) || 1;
      state.account.max_num_pages = parseInt(planMetadata?.max_num_pages) || 30;
      state.account.max_num_words = parseInt(planMetadata?.max_num_words) || 15000;
      state.account.max_num_queries = parseInt(planMetadata?.max_num_queries) || 30;

      state.account.maxNumConversationsReached = parseInt(user?.num_conversations) >= state.account.max_num_conversations;
      state.account.maxNumProjectsReached = parseInt(user?.num_projects) >= state.account.max_num_projects;
      state.account.maxNumPagesReached = parseInt(user?.num_pages) >= state.account.max_num_pages;
      state.account.maxNumWordsReached = parseInt(user?.num_words) >= state.account.max_num_words;
      state.account.maxNumQueriesReached = parseInt(user?.num_answers) >= state.account.max_num_queries;

      if (state.account?.role !== 'admin') {
        state.account.newProjectsDisabled = state.account.maxNumProjectsReached;
        state.account.newFileUploadsDisabled = state.account.maxNumPagesReached || state.account.maxNumWordsReached;
        state.account.newConversationsDisabled = state.account.maxNumConversationsReached;
        state.account.newQueriesDisabled = state.account.maxNumQueriesReached;
      } else {
        state.account.max_num_projects = 100;
        state.account.max_num_conversations = 100;
        state.account.max_num_pages = 1000000;
        state.account.max_num_words = 1000000000;
        state.account.max_num_queries = 1000000;

        state.account.newProjectsDisabled = false;
        state.account.newFileUploadsDisabled = false;
        state.account.newConversationsDisabled = false;
        state.account.newQueriesDisabled = false;
      }
    },
    createCheckoutSessionSuccess(state, action) {
      state.isLoading = false;
      state.account.subscriptions = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetApiKeys, getAccountSubscriptionSuccess, createCheckoutSessionSuccess, setAccountSuccess, upsertApiKeysSuccess, startLoading, hasError } = slice.actions;

// Get the customer's subscription
// Subscription details are synced to the subscriptions sub-collection in the user's corresponding customer doc.
export function getAccountSubscription(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // Query the user data from firestore and stripe. Create a new user object with the data from both sources.
      const userRef = doc(collection(DB, 'users'), user.id);
      const userSnap = await getDoc(userRef);
      let account = {};
      let currentSubscription = {};
      if (userSnap.exists()) {
        account = userSnap.data();
        account.id = user.id;
        account.email = user.email;
        account.role = user.email.includes('@eudaimoniatech.io') ? 'admin' : 'user';
        account.num_projects = user.num_projects;
        account.num_conversations = user.num_conversations;
        account.num_pages = user.num_pages;
        account.num_words = user.num_words;
        account.num_lines = user.num_lines;
        account.num_answers = user.num_answers;
        account.num_queries = user.num_queries;
        account.num_files = user.num_files;
        account.num_pending_files = user.num_pending_files;
        account.num_completed_files = user.num_completed_files;
        account.num_failed_files = user.num_failed_files;
        account.apiKeys = user.apiKeys || {};
        account.stripeLink = user.stripeLink || '';
        account.stripeId = user.stripeId || '';
        const colRef = collection(DB, `users/${user.id}`, 'subscriptions');
        const q = query(colRef, orderBy('created', 'desc'));
        const colExist = await getDocs(q);
        // if (colExist.empty) {
        //   account.subscriptions = [];
        //   account.subscriptions[0] = {};
        //   account.subscriptions[0].items = [];
        //   account.subscriptions[0].items[0] = {};
        //   account.subscriptions[0].items[0].plan = {};
        //   account.subscriptions[0].items[0].plan.id = null;
        //   account.subscriptions[0].items[0].price = {};
        //   account.subscriptions[0].items[0].price.product = {};
        //   account.subscriptions[0].items[0].price.product.metadata =  {};
        // } else {
        //   // Sort by descending date
        //   currentSubscription = colExist.docs.map((doc) => doc.data());
        //   account.subscriptions = currentSubscription;
        // }
        currentSubscription = colExist.docs.map((doc) => doc.data());
        account.subscriptions = currentSubscription;
      }
      dispatch(slice.actions.getAccountSubscriptionSuccess(account));
      return account;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setAccount(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(getAccountSubscription(user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function upsertApiKeys(user, newApiKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const userRef = doc(collection(DB, 'users'), user.id);
      const response = await setDoc(
        userRef,
        {
          uid: user.id,
          apiKeys: newApiKey,
        },
        {
          merge: true,
        }
      );
      user.apiKeys = newApiKey;
      dispatch(slice.actions.upsertApiKeysSuccess(user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function subscribe(user, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // BL executed by https://eudaimonia.app.n8n.cloud/workflow/1076
      window.location.assign(data.payload.call_to_action_link);

      // const selectedPrice = {
      //   price: data.price_id,
      //   quantity: 1
      // };
      // const checkoutSession = {
      //   automatic_tax: true,
      //   tax_id_collection: true,
      //   allow_promotion_codes: true,
      //   trial_period_days: 7,
      //   line_items: [selectedPrice],
      //   success_url: `${window.location.origin}/dashboard/user/checkout`,
      //   cancel_url: `${window.location.origin}/dashboard/user/checkout`,
      //   client_reference_id: user.stripeId,
      // };
      // const docRef = doc(DB, 'users', user.id);
      // const colRef = collection(docRef, 'checkout_sessions');
      // const addRef = await addDoc(colRef, checkoutSession);
      // onSnapshot(addRef, (snap) => {
      //   const { error, url } = snap.data();
      //   if (error) {
      //     console.error(error);
      //   }
      //   if (url) {
      //     // window.location.assign(url);
      //     window.location.assign(data.payload.call_to_action_link);
      //   }
      // });
      // console.log(response);
      // dispatch(slice.actions.createCheckoutSessionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(DB, 'users', uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(DB, 'users', uid), data, { merge: true });
}

// export function getUserByEmail(email) {
//   return getDoc(doc(DB, "users", email)).then(format);
// }
// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(DB, 'users', uid), data);
}

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  }
  // Handle a single doc
  return response.exists() ? formatDoc(response) : null;
}
