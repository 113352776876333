// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Card, Divider, InputAdornment, Stack } from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
// redux
import { setAccount, upsertApiKeys } from '../../../../redux/slices/account';
import { useDispatch, useSelector } from '../../../../redux/store';
import { PATH_SHOPIFY_APP_INSTALL } from '../../../../routes/paths';

const shopifyColor = lightGreen[700];

export default function AccountShopifyChangeApiKeys() {
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAccount(user));
  }, [dispatch, user]);

  const { account, error } = useSelector((state) => state.account);

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const ChangeShopifyApiSchema = Yup.object().shape({
    apiKey: Yup.string().required('API Key is required'),
    password: Yup.string().required('Password is required'),
    shopSubdomain: Yup.string().required('Shop Subdomain is required'),
    locationId: Yup.string().required('Location ID is required'),
  });

  const defaultValues = useMemo(
    () => ({
      apiKey: account?.apiKeys?.apiKey || user?.apiKeys?.apiKey || '',
      password: account?.apiKeys?.password || user?.apiKeys?.password || '',
      shopSubdomain: account?.apiKeys?.shopSubdomain || user?.apiKeys?.shopSubdomain || '',
      sharedSecret: account?.apiKeys?.sharedSecret || user?.apiKeys?.sharedSecret || '',
      locationId: account?.apiKeys?.locationId || user?.apiKeys?.locationId || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, user]
  );

  const methods = useForm({
    resolver: yupResolver(ChangeShopifyApiSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newApiKey = {
        apiKey: data.apiKey,
        password: data.password,
        shopSubdomain: data.shopSubdomain,
        locationId: data.locationId,
      };
      if (newApiKey.apiKey !== '' && newApiKey.password !== '' && newApiKey.shopSubdomain !== '' && newApiKey.locationId !== '') {
        dispatch(upsertApiKeys(user.uid, data));
        enqueueSnackbar('Update success!');
      }
    } catch {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <Card sx={{ p: 0 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" alignItems="flex-end" justifyContent="space-between" sx={{ py: 2.5, px: 0 }}>
          <RHFTextField
            name="shopSubdomain"
            label="Shop Subdomain *"
            fullWidth={false}
            sx={{ width: 0.7 }}
            placeholder="your-shop-subdomain only (without .myshopify.com)"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:link-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
          />

          <Button
            href={`${PATH_SHOPIFY_APP_INSTALL}/?shop=${defaultValues?.shopSubdomain}.myshopify.com`}
            target="_self"
            variant="contained"
            sx={{
              width: 0.28,
              mt: 0.0,
              bgcolor: shopifyColor,
              '&:hover': { bgcolor: shopifyColor },
            }}
            size="xlarge"
          >
            Install Shopify APP
            {/* <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='-10 0 48 48'
              width='48px'
              height='40px'
            >
              <image xlinkHref='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCI+PHBhdGggZmlsbD0iIzdjYjM0MiIgZD0iTTM3LjIxNiAxMS43OGMtLjAyMy0uMjExLS4yMTEtLjMwNS0uMzUxLS4zMDVzLTMuMjEtLjIzNC0zLjIxLS4yMzQtMi4xMzItMi4xMzItMi4zOS0yLjM0M2MtLjIzNC0uMjM0LS42OC0uMTY0LS44NjctLjExNy0uMDIzIDAtLjQ2OS4xNDEtMS4xOTUuMzc1LS43MjYtMi4wODYtMS45NjgtMy45ODQtNC4xOTQtMy45ODRoLS4yMTFDMjQuMTg3IDQuMzc1IDIzLjM5MSA0IDIyLjczNSA0Yy01LjE1NSAwLTcuNjM5IDYuNDQ0LTguNDEyIDkuNzI1LTIuMDE1LjYzMy0zLjQ0NSAxLjA1NC0zLjYwOSAxLjEyNS0xLjEyNS4zNTEtMS4xNDguMzc1LTEuMjg5IDEuNDI5LS4xMTcuNzk3LTMuMDQ2IDIzLjQ1Ni0zLjA0NiAyMy40NTZMMjkuMTc5IDQ0bDEyLjM3My0yLjY3MWMuMDIzLS4wNDctNC4zMTItMjkuMzM4LTQuMzM2LTI5LjU0OXptLTkuMjc5LTIuMjk3Yy0uNTYyLjE2NC0xLjI0Mi4zNzUtMS45MjEuNjA5di0uNDIxYzAtMS4yNjUtLjE2NC0yLjI5Ni0uNDY5LTMuMTE3IDEuMTcxLjE0MSAxLjg5OCAxLjQzIDIuMzkgMi45Mjl6bS0zLjgyLTIuNjcxYy4zMDUuNzk3LjUxNiAxLjkyMi41MTYgMy40Njh2LjIzNGMtMS4yNjUuMzk4LTIuNjAxLjc5Ny0zLjk4NCAxLjI0Mi43NzMtMi45NTIgMi4yNS00LjQwNSAzLjQ2OC00Ljk0NHptLTEuNS0xLjQ1M2MuMjM0IDAgLjQ2OS4wOTQuNjU2LjIzNC0xLjY2NC43NzMtMy40MjEgMi43MTgtNC4xNDggNi42NTUtMS4xMDEuMzUxLTIuMTU2LjY1Ni0zLjE2My45ODQuODQ0LTIuOTk5IDIuOTUzLTcuODczIDYuNjU1LTcuODczeiIvPjxwYXRoIGZpbGw9IiM1NThiMmYiIGQ9Ik0zNi44NjUgMTEuNDI4Yy0uMTQxIDAtMy4yMS0uMjM0LTMuMjEtLjIzNHMtMi4xMzItMi4xMzItMi4zOS0yLjM0M2EuNDU4LjQ1OCAwIDAwLS4zMDUtLjE0MUwyOS4yNDkgNDRsMTIuMzczLTIuNjcxcy00LjMzNS0yOS4zMzgtNC4zNTktMjkuNTQ5YS42MzYuNjM2IDAgMDAtLjM5OC0uMzUyeiIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yNC43OTIgMTguNTkzbC0xLjQ3NSA0LjQ0OXMtMS4zMzctLjcxNS0yLjkyNy0uNzE1Yy0yLjM3NCAwLTIuNDg5IDEuNDk4LTIuNDg5IDEuODY3IDAgMi4wMjggNS4zMDEgMi44MTIgNS4zMDEgNy41ODMgMCAzLjc1Ny0yLjM3NCA2LjE3Ny01LjU3OCA2LjE3Ny0zLjg3MiAwLTUuODA4LTIuMzk3LTUuODA4LTIuMzk3bDEuMDM3LTMuNDExczIuMDI4IDEuNzUyIDMuNzM0IDEuNzUyYzEuMTI5IDAgMS41OS0uODc2IDEuNTktMS41MjEgMC0yLjY1MS00LjMzMy0yLjc2Ni00LjMzMy03LjE0NSAwLTMuNjY1IDIuNjI4LTcuMjE0IDcuOTUyLTcuMjE0IDEuOTgxLS4wMjQgMi45OTYuNTc1IDIuOTk2LjU3NXoiLz48L3N2Zz4=' />
            </svg> */}
          </Button>
        </Stack>
        <Divider />
        <Stack spacing={2} alignItems="flex-end" sx={{ p: 3, py: 2.5, px: 1, bgcolor: 'background.neutral' }}>
          <RHFTextField disabled name="apiKey" type="password" label="API Key *" />

          <RHFTextField disabled name="password" type="password" label="Password *" />

          <RHFTextField disabled name="sharedSecret" type="password" label="Shared Secret" />

          <RHFTextField disabled name="locationId" type="password" label="Location Id *" />
          {/* ToDo:  Remake this if */}

          <LoadingButton disabled type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
