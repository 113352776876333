const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description_z: `Need help? \n Please check our`,
    documentation_z: `guide`,
    description: `Need help? Please contact our`,
    documentation: `team!`,
  },
  sofia_your_business_oracle: `sοφία, your business oracle`,
  enhance_your_knowledge_base: `Empower your Knowledge Base! Query corporate documents and data with the power of ChatGPT.`,
  export_conversation: `Conversation export`,
  dashboard: `Dashboard`,
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `project`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `Description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  name: `Name`,
  created_at: `Created at`,
  updated_at: `Updated at`,
  size: `Size`,
  type: `Type`,
  status: `Status`,
  file: `Files`,
  upload: `Upload`,
  download: `Download`,
  delete: `Delete`,
  copy_link: `Copy link`,
  remove_all: `Remove all`,
  go_back: `Go back`,
  not_found: 'Not found',
  no_results_found_for: 'No results found for',
  try_checking_for_typos_or_using_complete_words: 'Try checking for typos or using complete words.',
  please_enter_keywords: 'Please enter keywords',
  delete_conversation: `Delete conversation`,
  tts_voice_test: `Hi there! I'm your AI assistant. How can I help you today?`,
  chats: {
    title: `Chats`,
    search_contacts: `Search chats...`,
    recipients: `Bots`,
    send_to: `Send to`,
    type_message: 'Type a message',
    search_available_questions: `Search available questions...`,
    how_can_i_help_you: `Hello, how can I help you?`,
    conversations: `Conversations`,
    create_new_conversation_on_project: `Create new conversation on project`,
    new_conversation_created_successfully: `New conversation created successfully`,
    new_conversation_creation_failed: `New conversation creation failed`,
  },
  users: {
    accounts: {
      settings: `Account Settings`,
    },
  },
  files: {
    exclude_from_indexes: `Exclude from indexes`,
    drop_or_select_files: `Drop or select files`,
    drop_files_here_or_click: `Drop files here or click`,
    browse: `browse`,
    through_your_machine: `through your machine`,
    upload_files: `Upload files`,
    search_files: `Search...`,
    drop_files_here_to_upload: `Drop files here to upload`,
    files_size: `Files size`,
    files_progress: `Files progress`,
    no_files: `No files`,
    delete: `Delte files`,
    preview: `Files preview`,
    failed: `Files failed`,
    deleted: `Files deleted`,
    approved: `Files approved`,
    rejected: `Files rejected`,
    pending: `Files pending`,
    processed: `Files processed`,
    uploading: `Files uploading`,
    limit: `Files limit`,
    cancelled: `Files cancelled`,
    type_not_allowed: `Files type not allowed`,
    max_size: `Files max size`,
    max_files: `Files max files`,
    required: `Please select a file`,
    uploaded_successfully: `Files uploaded successfully`,
    upload_started: `Files upload started`,
    upload_failed: `Files upload failed`,
    upload_cancelled: `Files upload cancelled`,
    deleted_successfully: `Files deleted successfully`,
    deletion_started: `Files deletion started`,
    deletetion_failed: `Files deletetion failed`,
  },
  projects: {
    save: `Save project`,
    saved_successfully: `Project saved successfully`,
    save_failed: `Project save failed`,
    delete: `Delete project`,
    deleted_successfully: `Project deleted successfully`,
    delete_failed: `Project delete failed`,
    create: `Create project`,
    is_active: `Project is active`,
    projects_status: `Projects status`,
    projects_available: `Projects available`,
    projects_used: `Projects used`,
    project_name_is_required: `Project name is required`,
    project_description_is_required: `Project description is required`,
    use_with_chatgpt_plugin: `Use with ChatGPT plugin`,
    questions_master_prompt: `Questions master prompt`,
    answers_master_prompt: `Answers master prompt`,
    query_top_k: `Query Top K query results`,
    chunk_questions: `N° questions to generate per chunk`,
    chunks: `Chunks`,
    chunk_size: `Chunk size`,
    chunk_overlap: `Chunk overlap`,
    temperature_questions: `Questions generation temperature`,
    temperature_answers: `Answers temperature`,
    max_tokens_answers: `Max tokens per answer`,
    model_name_answers: `Answers model`,
    model_name_questions: `Questions model`,
    embedding_model: `Embedding model`,
    hybrid_convex_alpha: `Hybrid Convex Scale Alpha`,
    qa_language: `Project language`,
    tts_settings: `Text to speech settings`,
    voice_for_questions: `Voice for questions`,
    voice_for_answers: `Voice for answers`,
    conversation_deleted_successfully: `Conversation deleted successfully`,
    delete_conversation_failed: `Delete conversation failed`,
    telephony_app_url: `URL App Telefonia`,
    voice_app_url: `URL App Voce`,
    index_has_vectors: `Index has vectors`,
    index_has_no_vectors: `Index has no vectors`,
    error_checking_vectors: `Error checking vectors`,
  },
  login: {
    title: `Login`,
    dont_have_an_account: `Don’t have an account?`,
    sign_up: `Sign up`,
    already_have_an_account: `Already have an account?`,
    log_in: `Log in`,
    reset_password: `Reset password`,
    forgot_password: `Forgot password?`,
    email_address: `Email address`,
    password: `Password`,
    get_started: `Get started`,
    loading: `Loading…`,
    hi_welcome_back: `Hi, Welcome Back`,
    enter_your_details_below: `Enter your details below.`,
    use_email: `Use email :`,
    dont_have_an_account_sign_up: `Don’t have an account? Sign up`,
    by_clicking_sign_up_you_agree_to: `By clicking “Sign up”, you agree to`,
    our_terms_of_service_and_privacy_policy: `our terms of service and privacy policy.`,
    welcome_to: `Welcome`,
    sign_in: `Sign in`,
    login_to_your_account: `Login to your account`,
    request_sent_success: `Request sent successfully`,
    remember_me: `Remember me`,
    log_in_with_google: `Log in with Google`,
    or: `or`,
  },
  register: {
    title: `Register`,
    already_have_an_account: `Already have an account?`,
    log_in: `Log in`,
    manage_the_job_more_effectively_with: `Manage the job more effectively with`,
    get_started_absolutely_free: `Get started absolutely free.`,
    free_forever_no_credit_card_needed: `Free forever. No credit card needed.`,
    by_registering_i_agree_to_our: `By registering, I agree to your`,
    and: `and`,
    terms_of_service: `Terms of Service`,
    already_have_an_account_login: `Already have an account? Log in`,
    password_rules: `Passwords must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character`,
  },
  new_password: {
    title: `New password`,
    request_sent_successfully: `Request sent successfully!`,
    weve_sent_a_6_digit_confirmation_email_to_your_email: `We've sent a 6-digit confirmation email to your email.`,
    please_enter_the_code_in_below_box_to_verify_your_email: `Please enter the code in below box to verify your email.`,
    dont_have_a_code: `Don’t have a code?`,
    resend_code: `Resend code`,
    code_is_required: `Code is required`,
    code_must_be_a_6_digit_number: `Code must be a 6-digit number`,
  },
  reset_password: {
    title: `Reset password`,
    forgot_your_password: `Forgot your password?`,
    please_enter_the_email_address_associated_with_your_account: `Please enter the email address associated with your account and We will email you a link to reset your password.`,
    back: `Back`,
  },
  nav_items: {
    dashboard: `Dashboard`,
    management: `Management`,
    users: `Users`,
    user: `User`,
    account: `Account`,
    settings: `Settings`,
    logout: `Logout`,
    login: `Login`,
    register: `Register`,
    new_password: `New password`,
    reset_password: `Reset password`,
    profile: `Profile`,
    edit_profile: `Edit profile`,
    change_password: `Change password`,
    user_management: `User management`,
    user_list: `User list`,
    user_edit: `User edit`,
    user_create: `User create`,
    user_detail: `User detail`,
    user_delete: `User delete`,
    chat: `Chat`,
    mail: `Mail`,
    subscription: `Subscription`,
    files: `Files`,
    invoices: `Invoices`,
    projects: `Projects`,
    questions: `Questions`,
    conversations: `Conversations`,
  },
  breadcrumbs: {
    home: `Home`,
    dashboard: `Dashboard`,
    account: `Account`,
    upload_files: `Upload files`,
    manage_files: `Manage files`,
    data: `Data`,
    projects: `Projects`,
    choose_your_plan: `Choose your plan`,
    payment: `Payment`,
    checkout: `Checkout`,
    subscription: `Subscription`,
    billing_address: `Billing address`,
    done: `Done`,
    new_project: `New project`,
    edit_project: `Edit`,
    list: `List`,
  },
  current_subscription_info: `Current subscription info`,
  you_are_signed_in_as: `You are signed in as `,
  check_your_subscribed_plan_here: `Check your subscribed plan here`,
  settings: 'Settings',
  your_plan_status_is: `Your plan status is`,
  active: `Active`,
  expired: `Expired`,
  inactive: `Inactive`,
  current_plan: `Current`,
  plan: `Plan`,
  price: `Price`,
  next_payment: `Next payment`,
  properties: `Properties`,
  modified: `Modified`,
  subscribe: `Subscribe`,
  buy_now: `Buy now`,
  start_free_trial: `Start free trial`,
  cancel_subscription: `Cancel subscription`,
  your_current_plan: `Your current plan`,
  plan_activated_successfully: `Plan activated successfully`,
  plan_deactivated_successfully: `Plan deactivated successfully`,
  plan_activation_failed: `Plan activation failed`,
  checkout_process_started_plase_wait: `Checkout process started, please wait...`,
  popular: `Popular`,
  save_changes: `Save changes`,
  updgrade_plan: `Upgrade plan`,
  available: `Available`,
  used: `Used`,
  used_f: `Used`,
  pages: `Pages`,
  words: `Words`,
  queries: `Queries`,
  answers: `Answers`,
  included_in_plan: `Included in plan`,
  included_in_plan_f: `Included in plan`,
  plan_limit_reached: `Plan limit reached`,
  number_of_projects_limit_reached: `Number of projects limit reached`,
  welcome_back: `Welcome back! \n `,
  upgrade_your_plan_to_create_more_projects: `Upgrade your plan to create more projects. \nBut you can still upload files and ask questions to Sofia GPT.`,
  create_new_projects_upload_your_files_and_ask_questions_to_sofia_gpt: `Create new projects, upload your files and \n ask questions to Sofia GPT`,
  messages: `Messages`,
  ai_faqs: `AI FAQs`,
  bot_answers: `Bot answers`,
  no_files_uploaded: `No files uploaded`,
  downgrade_plan: `Downgrade plan`,
  email_must_be_a_valid_email_address: `Email must be a valid email address`,
  email_is_required: `Email is required`,
  password_is_required: `Password is required`,
  first_name_is_required: `First name is required`,
  last_name_is_required: `Last name is required`,
  email_address: `Email address`,
  first_name: `First name`,
  last_name: `Last name`,
  this_email_is_invalid: `This email is invalid.`,
  this_account_has_been_deactivated_or_deleted: `This account has been deactivated or deleted.`,
  this_user_was_not_found_have_you_created_your_account: `This user was not found: have you created your account?`,
  this_email_was_already_used_for_another_account: `This email was already used for another account.`,
  service_seems_to_be_unavailable_please_try_again_later: `Service seems to be unavailable, please try again later.`,
  please_log_out_and_log_in_again_to_verify_your_identify_then_change_your_email: `Please log out and log in again to verify your identify, then change your email.`,
  that_s_a_lot_of_unsuccessful_login_attempts_wait_a_bit_before_trying_again_or_reset_your_password: `That's a lot of unsuccessful login attempts! Wait a bit before trying again or reset your password.`,
  the_password_is_invalid: `The password is invalid.`,
  this_password_is_too_weak_please_use_more_letters_and_digits: `This password is too weak: please use more letters and digits.`,
  confirm_password_is_required: `Confirm password is required`,
  password_does_not_match: `Password does not match with confirm password`,
  confirm_password: `Confirm password`,
  available_only_on_enterprise_plan: `Available only on enterprise plan`,
  refresh_token: `Refresh token`,
  token_updated_successfully: `Token updated successfully`,
  token_update_failed: `Token update failed`,
  generate_token: `Generate token`,
  tokens: `Tokens`,
  questions: {
    list_title: `FAQ: List`,
    list_heading: `FAQ List`,
    chat_name: `Chat`,
    faqs_name: `FAQs`,
    new_question_action: `New Question`,
    label_question: `Question`,
    label_questions: `Questions`,
    label_updated_at: `Updated At`,
    label_topic: `Topic`,
    start_date: `Start date`,
    end_date: `End date`,
    search_placeholder: `Search question or topic...`,
    delete: `Delete`,
    edit: `Edit`,
    required: `Question is required`,
    updated_successfully: `FAQ saved successfully`,
    update_failed: `Save failed`,
    deleted_successfully: `FAQ deleted successfully`,
    delete_failed: `Delete failed`,
    asked_successfully: `Received answer from GPT`,
    ask_failed: `Failed to ask GPT`,
    label_answer: `Answer`,
    save: `Save`,
    generate_answer: `Auto-generate answer`,
    export_to_csv: `Export to CSV`,
    export_to_excel: `Export to Excel`,
    faqs_deletion_started: `Faqs deletion started.`,
    faqs_deletion_successfully: `Faqs deleted successfully`,
    faqs_deletetion_failed: `Faqs deletetion failed`,
    faqs_batch_create_started: `Faqs batch create started`,
    faqs_batch_create_successfully: `Faqs batch create successfully`,
    faqs_batch_create_failed: `Faqs batch create failed`,
    faqs_batch_create_answers_started: `Faqs batch create answers started`,
    faqs_batch_create_answers_successfully: `Faqs batch create answers successfully`,
    faqs_batch_create_answers_failed: `Faqs batch create answers failed`,
    no_questions_selected: `No questions selected`,
  },
  phone_number: `Phone number`,
  address: `Address`,
  country: `Country`,
  state: `State`,
  city: `City`,
  zip_code: `Zip code`,
  about: `About`,
};

export default en;
