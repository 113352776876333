export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& label': {
            fontSize: theme.typography.body3.fontSize,
          },
        },
        input: {
          fontSize: theme.typography.body3.fontSize,
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: theme.spacing(0, 1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: theme.shape.borderRadius,
            fontSize: theme.typography.body3.fontSize,
          },
        },
      },
    },
  };
}
