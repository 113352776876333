import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  embeddingModel: 'text-embedding-3-small',
};

const slice = createSlice({
  name: 'embeddingModel',
  initialState,
  reducers: {
    setEmbeddingModel(state, action) {
      state.embeddingModel = action.payload;
    },
  },
});

export const { setEmbeddingModel } = slice.actions;

export default slice.reducer;
