// @mui
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

//
import { Upload, UploadAvatar, UploadBox } from '../upload';

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar error={!!error} file={field.value} {...other} />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadBox({ name, ...other }) {
  const { control } = useFormContext();

  return <Controller name={name} control={control} render={({ field, fieldState: { error } }) => <UploadBox files={field.value} error={!!error} {...other} />} />;
}

RHFUploadBox.propTypes = {
  name: PropTypes.string,
};

RHFUpload.whyDidYouRender = {
  logOnDifferentValues: true,
  customName: 'RHFUpload',
};

export function RHFUpload({ name, multiple, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const allowed_types = {
          'text/plain': ['.md', '.txt', '.rtf', '.html'],
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
          'image/*': ['.jpg', '.gif', '.png', '.jpeg'],
          // 'video/*': ['.mp4', '.webm', '.mov', '.flv', '.avi', '.wmv', '.mkv'],
          // 'audio/*': ['.mp3', '.m4a', '.wav', '.ogg', '.flac'],
        };
        return multiple ? (
          <Upload
            accept={allowed_types}
            files={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        ) : (
          <Upload
            accept={allowed_types}
            file={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUpload.propTypes = {
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
};
