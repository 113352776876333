import './wdyr';
// i18n
import './locales/i18n';
// highlight
import './utils/highlight';
// scroll bar
import 'simplebar/src/simplebar.css';
// lightbox
import 'react-image-lightbox/style.css';
// map
// import 'mapbox-gl/dist/mapbox-gl.css';
// editor
import 'react-quill/dist/quill.snow.css';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

// import { AuthProvider } from './contexts/AwsCognitoContext';
//
import App from './App';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version
// import { AuthProvider } from './contexts/JWTContext';
// import { AuthProvider } from './contexts/Auth0Context';
import { AuthProvider } from './contexts/FirebaseContext';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
// redux
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const isLocalhost = Boolean(
  window.location.hostname.includes('localhost') ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// if (!isLocalhost) {
// Sentry.init({
//   dsn: "https://6e52bdabafa34d689a005b5c6450a8dc@o623082.ingest.sentry.io/6700860",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });
// }

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  {/* <PostHogProvider
                    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
                    options={options}
                  > */}
                  <App />
                  {/* </PostHogProvider> */}
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
