import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountReducer from './slices/account';
// slices
import chatReducer from './slices/chat';
import embeddingModelReducer from './slices/embeddingModel';
import { filesReducer } from './slices/files';
import productReducer from './slices/product';
import questionReducer from './slices/question';
import voiceReducer from './slices/voice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const questionPersistConfig = {
  key: 'question',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const voicePersistConfig = {
  key: 'voice',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const accountPersistConfig = {
  key: 'account',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const filesPersistConfig = {
  key: 'files',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const embeddingModelPersistConfig = {
  key: 'embeddingModel',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  chat: chatReducer,
  question: persistReducer(questionPersistConfig, questionReducer),
  product: persistReducer(productPersistConfig, productReducer),
  account: persistReducer(accountPersistConfig, accountReducer),
  voice: persistReducer(voicePersistConfig, voiceReducer),
  files: persistReducer(filesPersistConfig, filesReducer),
  embeddingModel: persistReducer(embeddingModelPersistConfig, embeddingModelReducer),
});

export { rootPersistConfig, rootReducer };
