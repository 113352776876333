import { createSlice } from '@reduxjs/toolkit';

// utils
import axiosInstance from '../../utils/axios';
// ------------------------------------------------
//
import {
  appendMessageToConversationInFirestore,
  createDefaultConversationInFirestore,
  createNewConversationInFirestore,
  deleteConversationInFirestore,
  deleteMessageInFirestore,
  getConversationByProjectInFirestore,
  getProjectFromFirestore,
  getProjectsFromFirestore,
  getUserByCustomerIdInFirestore,
  getUserInFirestore,
  saveNewConversationInFirestore,
  updateConversationInFirestore,
  updateProjectInFirestore,
  updateUserByCustomerIdInFirestore,
  updateUserInFirestore,
} from '../../utils/firestore';
//
import uuidv4 from '../../utils/uuidv4';
import { dispatch } from '../store';

const { REACT_APP_SOFIA_API } = process.env;

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

export async function saveNewConversation(uid, pid, cid, data) {
  try {
    // console.log('saveConversation', uid, pid, cid, data);
    const conversation = await saveNewConversationInFirestore(uid, pid, cid, data);
    return conversation;
  } catch (error) {
    console.error('Error updating conversation', error);
    throw error;
  }
}

export async function getProjects(uid) {
  try {
    const projects = await getProjectsFromFirestore(uid);
    return projects;
  } catch (error) {
    console.error('Error getting projects', error);
    throw error;
  }
}

export async function getProjectByCode(uid, code) {
  try {
    // console.log('getProjectByCode', uid, code);
    const projects = await getProjects(uid);
    const project = projects.find((project) => project.code === code);
    return project;
  } catch (error) {
    console.error('Error getting project by code', error);
    throw error;
  }
}

export async function getProject(uid, pid) {
  try {
    // console.log('getProject', uid, pid);
    const project = getProjectFromFirestore(uid, pid);
  } catch (error) {
    console.error('Error getting project', error);
    throw error;
  }
}

// Update a project

export async function updateProject(uid, pid, project) {
  try {
    // console.log('updateProject', uid, pid, project);
    await updateProjectInFirestore(uid, pid, project);
  } catch (error) {
    console.error('Error updating project', error);
    throw error;
  }
}

// Get user by uid
export async function getUser(uid) {
  try {
    // console.log('getUser', uid);
    const user = await getUserInFirestore(uid);
    return user;
  } catch (error) {
    console.error('Error getting user', error);
    throw error;
  }
}

// Get user by stripeCustomerId
export async function getUserByCustomerId(customerId) {
  try {
    // console.log('getUserByCustomerId', customerId);
    const user = await getUserByCustomerIdInFirestore(customerId);
    return user;
  } catch (error) {
    console.error('Error getting user by customerId', error);
    throw error;
  }
}

export async function updateUser(uid, data) {
  try {
    // console.log('updateUser', uid, data);
    const user = await updateUserInFirestore(uid, data);
    return user;
  } catch (error) {
    console.error('Error updating user', error);
    throw error;
  }
}

// Update a user by their stripeCustomerId
export async function updateUserByCustomerId(customerId, data) {
  try {
    // console.log('updateUserByCustomerId', customerId, data);
    const user = await updateUserByCustomerIdInFirestore(customerId, data);
    return user;
  } catch (error) {
    console.error('Error updating user by customerId', error);
    throw error;
  }
}

/** ** CONTACTS *** */
export async function getProjectsContacts(uid) {
  try {
    const projects = await getProjects(uid);
    const contacts = [];
    for (const project of projects) {
      contacts.push({
        id: project.id,
        name: project.name,
        username: `agent.sofia.${project.id.substring(0, 5)}`,
        avatar: '',
        lastActivity: new Date().toISOString(),
        status: 'online',
        position: 'agent',
        telephonyAppUrl: project.telephony_app_url,
        voiceAppUrl: project.voice_app_url,
      });
    }
    return contacts;
  } catch (error) {
    console.error('Error updating user by customerId', error);
    throw error;
  }
}

/** ** CONVERSATIONS *** */
export async function getConversationsByUser(uid) {
  try {
    // console.log('getConversationsByUser', uid);
    const conversations = [];
    const projects = await getProjects(uid);

    for (const project of projects) {
      const projectConversations = await getConversationByProject(uid, project.id);
      conversations.push(...projectConversations);
    }

    return conversations;
  } catch (error) {
    console.error('Error getting conversations by user', error);
    throw error;
  }
}

export async function getConversationByProject(uid, pid) {
  try {
    // console.log('getConversationByProject', uid, pid);
    const conversations = await getConversationByProjectInFirestore(uid, pid);
    return conversations;
  } catch (error) {
    console.error('Error getting conversation by project', error);
    throw error;
  }
}

export async function getConversationById(uid, pid, cid) {
  try {
    // console.log('getConversation', uid, pid, cid);
    const projectConversations = await getConversationByProject(uid, pid);
    const conversation = projectConversations.find((conversation) => conversation.id === cid);
    return conversation;
  } catch (error) {
    console.error('Error getting conversation', error);
    throw error;
  }
}

export async function createNewConversation(uid, pid, conversation) {
  try {
    // console.log('createConversation', uid, pid, conversation);
    const newConversation = await createNewConversationInFirestore(uid, pid, conversation);
    return newConversation;
  } catch (error) {
    console.error('Error creating conversation', error);
    throw error;
  }
}

export async function createDefaultConversation(uid, user, project) {
  try {
    // console.log('createDefaultConversation', uid, user, project);
    const defaultConversation = await createDefaultConversationInFirestore(uid, user, project);
    return defaultConversation;
  } catch (error) {
    console.error('Error creating default conversation', error);
    throw error;
  }
}

// export async function updateConversation(uid, pid, cid, conversation) {
//   try {
//     // console.log('updateConversation', uid, pid, cid, conversation);
//     await updateConversationInFirestore(uid, pid, cid, conversation);
//   } catch (error) {
//     console.error('Error updating conversation', error);
//     throw error;
//   }
// }
export async function appendMessageToConversation(uid, pid, cid, message) {
  try {
    // console.log('appendMessageToConversation', uid, pid, cid, message);
    const newMessage = await appendMessageToConversationInFirestore(uid, pid, cid, message);
    return newMessage;
  } catch (error) {
    console.error('Error appending message to conversation', error);
    throw error;
  }
}

const initialState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
  questions: [],
  questionsArray: [],
  isStreamingLoading: false,
  isStreaming: false,
  streamingError: null,
  audio: null,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SUCCESS
    getContactsSuccess(state, action) {
      state.isLoading = false;
      const contacts = action.payload;
      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      state.isLoading = false;
      const conversations = action.payload;
      state.conversations.byId = objFromArray(conversations);
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // CREATE CONVERSATION
    createConversationSuccess(state, action) {
      state.isLoading = false;
      const conversation = action.payload[0];
      state.conversations.byId[conversation.id] = conversation;
      state.conversations.allIds.push(conversation.id);
      state.activeConversationId = conversation.id;
    },

    saveConversationSuccess(state, action) {
      state.isLoading = false;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      state.isLoading = false;
      const conversation = action.payload;
      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
        localStorage.setItem('currentProject', conversation.projectId);
      } else {
        state.activeConversationId = null;
      }
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const { messageId, message, contentType, attachments, createdAt, senderId } = conversation;
      const newMessage = {
        id: messageId,
        messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };
      state.conversations.byId[state.activeConversationId].messages.push(newMessage);
      state.isLoading = false;
    },

    onReceiveMessage(state, action) {
      const conversation = state.conversations.byId[state.activeConversationId];
      const { messageId, senderId } = conversation;
      const id = messageId || uuidv4();
      const newMessage = {
        id,
        messageId: id,
        body: action.payload.data.answer,
        contentType: 'text',
        attachments: [],
        createdAt: new Date().toISOString(),
        senderId,
      };
      state.conversations.byId[state.activeConversationId].messages.push(newMessage);
      state.isLoading = false;
    },

    markConversationAsReadSuccess(state, action) {
      state.isLoading = false;
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    deleteMessageSuccess(state, action) {
      const { conversationId, messageId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.messages = conversation.messages.filter((message) => message.id !== messageId);
      }
      state.isLoading = false;
    },

    // DELETE CONVERSATION SUCCESS
    deleteConversationSuccess(state, action) {
      state.isLoading = false;
      const { conversationId } = action.payload;
      delete state.conversations.byId[conversationId];
      state.conversations.allIds = state.conversations.allIds.filter((id) => id !== conversationId);
      if (state.activeConversationId === conversationId) {
        state.activeConversationId = null;
      }
    },

    // DELETE CONVERSATION ERROR
    deleteConversationError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      state.isLoading = false;
      const participants = action.payload;
      state.participants = participants;
    },

    onAvailbleQuestionsSuccess(state, action) {
      state.isLoading = false;
      const questions = action.payload;
      state.questions = questions;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.isLoading = false;
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      state.isLoading = false;
      const recipients = action.payload;
      state.recipients = recipients;
    },

    addParticipants(state, action) {
      state.isLoading = false;
      const participants = action.payload;
      state.participants = participants;
    },

    // START STREAMING
    onStartTTS(state) {
      state.isStreamingLoading = true;
      state.isStreaming = false;
      state.streamingError = null;
    },

    // STREAMING HAS ERROR
    onHasErrorTTS(state, action) {
      state.isStreamingLoading = false;
      state.isStreaming = false;
      state.streamingError = action.payload;
    },

    // STREAMING SUCCESS
    onPlayTTS(state, action) {
      state.isStreamingLoading = false;
      state.isStreaming = true;
      state.audio = new Audio(URL.createObjectURL(action.payload));
    },

    onStreamingEnded(state) {
      state.isStreaming = false;
      state.isStreamingLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addRecipients,
  addParticipants,
  deleteMessageSuccess,
  onSendMessage,
  resetActiveConversation,
  onStartTTS,
  onStreamingEnded,
  deleteConversationSuccess,
  deleteConversationError,
} = slice.actions;

export function startTextToSpeechStreaming(message, projectId, isQuestion) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.onStartTTS());
    try {
      let text = message.body.split('<div class="sources-wrapper">')[0];
      text = text.split('<!-- HUMVAL -->')[0];
      const projectId = localStorage.getItem('currentProject');
      const URL = `${REACT_APP_SOFIA_API}/api/voice/stream`;
      await axiosInstance
        .post(
          URL,
          {
            text,
            projectId,
            isQuestion,
          },
          {
            headers: {
              ...axiosInstance.defaults.headers.common,
              CurrentProject: `${projectId}`,
            },
            responseType: 'blob',
          }
        )
        .then((response) => {
          dispatch(slice.actions.onPlayTTS(response.data));

          const currentState = getState();
          const { audio } = currentState.chat;

          audio.addEventListener('ended', () => {
            dispatch(slice.actions.onStreamingEnded());
          });

          audio.play();
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      dispatch(slice.actions.onHasErrorTTS(error));
    }
  };
}

export function askQuestion(state, action) {
  return async (dispatch) => {
    dispatch(slice.actions.onSendMessage(state, action));
    dispatch(slice.actions.startLoading());
    try {
      const conversationId = localStorage.getItem('currentConversationKey');
      const uid = localStorage.getItem('accountUid');
      const message = state.message;
      let pid = '';

      const conversations = await getConversationsByUser(uid);

      for (const conversation of conversations) {
        if (conversation.id === conversationId) {
          pid = conversation.projectId;
          break;
        }
      }

      if (!pid || pid === '') {
        throw new Error('Invalid project ID');
      }

      let isComingFromQaEditor = false;

      try {
        await appendMessageToConversation(uid, pid, conversationId, state);
      } catch (error) {
        isComingFromQaEditor = true;
        console.error('Call coming from QA editor, no need to append message to conversation');
      }

      const response = await axiosInstance.post(
        '/api/chat/ask-gpt',
        {
          question: message,
          conversation_id: conversationId,
        },
        {
          headers: {
            ...axiosInstance.defaults.headers.common,
            CurrentProject: pid,
          },
        }
      );

      const gptAnswer = {
        messageId: uuidv4(),
        message: response.data.answer,
        contentType: 'text',
        attachments: [],
        createdAt: new Date().toISOString(),
        senderId: pid,
      };

      if (!isComingFromQaEditor) {
        await appendMessageToConversation(uid, pid, conversationId, gptAnswer);
      }

      dispatch(slice.actions.onReceiveMessage(response));
    } catch (error) {
      console.error('Ask question error: ', error.message);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContacts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const contacts = await getProjectsContacts(uid);
      dispatch(slice.actions.getContactsSuccess(contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getConversations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      if (!uid) {
        throw new Error('User ID not found in localStorage');
      }
      const user = await getUser(uid);
      const projects = await getProjects(uid);
      let conversations = [];

      for (const project of projects) {
        const projectConversations = await getConversationByProject(uid, project.id);
        if (!projectConversations || projectConversations.length === 0) {
          const atLeastOneFileUploadSuccessful = project.files.some((file) => file.status === 'success');
          if (atLeastOneFileUploadSuccessful) {
            const conversation = await createDefaultConversation(uid, user, project);
            conversations.push(conversation);
          }
        } else {
          conversations = conversations.concat(projectConversations);
        }
      }

      conversations.sort((a, b) => {
        const aLatestMessage = a.messages.reduce((latest, message) => (Date.parse(message.createdAt) > Date.parse(latest.createdAt) ? message : latest), {
          createdAt: '1970-01-01T00:00:00Z',
        });

        const bLatestMessage = b.messages.reduce((latest, message) => (Date.parse(message.createdAt) > Date.parse(latest.createdAt) ? message : latest), {
          createdAt: '1970-01-01T00:00:00Z',
        });

        return Date.parse(bLatestMessage.createdAt) - Date.parse(aLatestMessage.createdAt);
      });

      dispatch(slice.actions.getConversationsSuccess(conversations));
    } catch (error) {
      console.error('Error in getConversations:', error);
      dispatch(slice.actions.hasError(error.message || 'An error occurred while fetching conversations'));
    }
  };
}

export function getConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const conversations = await getConversationsByUser(uid);

      const conversation = conversations.find((_conversation) => _conversation.id === conversationKey);
      if (conversation) {
        conversation.unreadCount = 0;
      }

      dispatch(slice.actions.getConversationSuccess(conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const conversations = await getConversationsByUser(uid);
      const conversation = conversations && conversations.length > 0 ? conversations.find((_conversation) => _conversation.id === conversationId) : null;
      if (conversation) {
        conversation.unreadCount = 0;
      }
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createConversation(participants) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const user = await getUser(uid);
      const newConversations = [];

      for (var project of participants) {
        const projectConversations = await getConversationByProject(uid, project.id);
        const counter = projectConversations.length;
        // Create a new object with updated name
        const updatedProject = {
          ...project,
          name: `${project.name}_${counter}`,
        };
        const conversation = await createDefaultConversation(uid, user, updatedProject);
        newConversations.push(conversation);
      }
      dispatch(slice.actions.createConversationSuccess(newConversations));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveConversation(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const conversation = await saveNewConversation(uid, data.projectId, data.id, data);
      dispatch(slice.actions.saveConversationSuccess(conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateConversation(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      await updateConversationInFirestore(uid, data.projectId, data.id, data);
      console.log('updateConversation', data);
      dispatch(slice.actions.saveConversationSuccess());
      // Refresh the conversation data after update
      dispatch(getConversation(data.id));
    } catch (error) {
      console.error('Error updating conversation:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');

      const participants = [];
      const conversations = await getConversationsByUser(uid);

      for (const conversation of conversations) {
        if (conversation.id === conversationKey) {
          participants.push(...conversation.participants);
        }
      }
      dispatch(slice.actions.getParticipantsSuccess(participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteConversation(conversationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const conversations = await getConversationsByUser(uid);
      const conversation = conversations.find((conv) => conv.id === conversationId);

      if (!conversation) {
        throw new Error('Conversation not found');
      }

      await deleteConversationInFirestore(uid, conversation.projectId, conversationId);
      dispatch(slice.actions.deleteConversationSuccess({ conversationId }));
    } catch (error) {
      console.error('Error deleting conversation:', error);
      dispatch(slice.actions.deleteConversationError(error.message));
    }
  };
}

export function deleteMessage(conversationId, messageId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const uid = localStorage.getItem('accountUid');
      const conversations = await getConversationsByUser(uid);
      const conversation = conversations.find((conv) => conv.id === conversationId);

      if (!conversation) {
        throw new Error('Conversation not found');
      }

      await deleteMessageInFirestore(uid, conversation.projectId, conversationId, messageId);
      dispatch(slice.actions.deleteMessageSuccess({ conversationId, messageId }));
    } catch (error) {
      console.error('Error deleting message:', error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}
