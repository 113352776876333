import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import useLocales from '../hooks/useLocales';

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  const { t } = useLocales();
  return searchQuery ? (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {t('not_found')}
      </Typography>
      <Typography variant="body2" align="center">
        {t('no_results_found_for')} &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. {t('try_checking_for_typos_or_using_complete_words')}
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2">{t('please_enter_keywords')}</Typography>
  );
}
