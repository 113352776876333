import jwtDecode from 'jwt-decode';

// routes
import { PATH_AUTH } from '../routes/paths';
//
import axiosInstance from './axios';

export const JWT_SECRET = 'K5mX069IKG82CMqh3Mzptd7e7lyb9lsoGO0BAtjho3cWtha/UZ70vfaMzGuZ6JmQ';

export const JWT_EXPIRES_IN = '30d';

const isValidToken = (accessToken) => {
  if (accessToken === null || accessToken === undefined) {
    return false;
  }
  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  // @ts-ignore
  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    localStorage.removeItem('accountUid');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('openAiAccessToken');
    localStorage.removeItem('currentProjectId');
    localStorage.removeItem('currentConversationKey');
    localStorage.removeItem('currentConversationName');
    // localStorage.removeItem('show_intercom_on_refresh');
    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

async function getOpenAIBearer(expiresIn) {
  // const privateKey = await jose.importPKCS8(pkcs8, alg);
  // const accountUid = localStorage.getItem('accountUid');
  const signedJWT = localStorage.getItem('accessToken');
  // const signedJWT = await new jose.SignJWT(
  //   {
  //     "user_id": accountUid
  //   }
  // )
  //   .setProtectedHeader({ alg: 'RS256' })
  //   .setIssuedAt()
  //   .setExpirationTime(expiresIn)
  //   // .sign(privateKey);
  //   .sign(new TextEncoder().encode(JWT_SECRET));

  localStorage.setItem('openAiAccessToken', signedJWT);
}

const setSession = (accessToken, uid = null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('accountUid', uid);

    const openAiAccessToken = localStorage.getItem('openAiAccessToken');

    // getOpenAIBearer('30d');

    if (openAiAccessToken === null || openAiAccessToken === undefined) {
      getOpenAIBearer('30d');
    }

    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken, { algorithm: 'RS256' });
    // const { exp: expOpenAi } = jose.jwtDecrypt(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accountUid');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('openAiAccessToken');
    localStorage.removeItem('currentProjectId');
    localStorage.removeItem('currentConversationKey');
    localStorage.removeItem('currentConversationName');
    // localStorage.removeItem('show_intercom_on_refresh');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
