// form
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
// components
import Iconify from '../../../components/Iconify';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// routes
import useLocales from '../../../hooks/useLocales';
import { PATH_AUTH } from '../../../routes/paths';

export default function LoginForm() {
  const { login } = useAuth();
  const { t } = useLocales();
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t('email_is_required')).email(t('email_must_be_a_valid_email_address')),
    password: Yup.string().required(t('password_is_required')),
    remember: Yup.boolean(),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const getPasswordErrorText = (error) => {
    if (!error) return undefined;
    if (error.code === 'auth/too-many-requests') return t('that_s_a_lot_of_unsuccessful_login_attempts_wait_a_bit_before_trying_again_or_reset_your_password');
    if (error.code === 'auth/wrong-password') return t('the_password_is_invalid');
    if (error.code === 'auth/weak-password') return t('this_password_is_too_weak_please_use_more_letters_and_digits');
    return undefined;
  };

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', {
          ...error,
          message: getPasswordErrorText(error),
        });
      }
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFTextField name="email" label={t('login.email_address')} />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <RHFCheckbox name="remember" label={t('login.remember_me')} />
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            {t('login.forgot_password')}
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('login.log_in')}
        </LoadingButton>
      </FormProvider>
      {/* <AuthFirebaseSocial /> */}
    </>
  );
}
