import { format, formatDistanceToNow, getTime, isValid } from 'date-fns';

// Helper function to safely parse dates
function safeParseDate(date) {
  if (date instanceof Date) return date;
  if (typeof date === 'number') return new Date(date);
  if (typeof date === 'string') {
    const parsedDate = new Date(date);
    return isValid(parsedDate) ? parsedDate : null;
  }
  return null;
}

export function fDate(date) {
  const parsedDate = safeParseDate(date);
  if (!parsedDate) return 'Invalid Date';
  try {
    return format(parsedDate, 'dd/MM/yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
}

export function fDateTime(date) {
  const parsedDate = safeParseDate(date);
  if (!parsedDate) return 'Invalid Date';
  try {
    return format(parsedDate, 'dd/MM/yyyy HH:mm');
  } catch (error) {
    console.error('Error formatting date and time:', error);
    return 'Invalid Date';
  }
}

export function fTimestamp(date) {
  const parsedDate = safeParseDate(date);
  if (!parsedDate) return null;
  try {
    return getTime(parsedDate);
  } catch (error) {
    console.error('Error getting timestamp:', error);
    return null;
  }
}

export function fDateTimeSuffix(date) {
  const parsedDate = safeParseDate(date);
  if (!parsedDate) return 'Invalid Date';
  try {
    return format(parsedDate, 'dd/MM/yyyy hh:mm a');
  } catch (error) {
    console.error('Error formatting date and time with suffix:', error);
    return 'Invalid Date';
  }
}

export function fToNow(date) {
  const parsedDate = safeParseDate(date);
  if (!parsedDate) return 'Invalid Date';
  try {
    return formatDistanceToNow(parsedDate, {
      addSuffix: true,
    });
  } catch (error) {
    console.error('Error calculating time distance:', error);
    return 'Invalid Date';
  }
}
