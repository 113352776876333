import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { getProjectFromFirestore, updateProjectInFirestore } from '../../utils/firestore';

const { REACT_APP_SOFIA_API } = process.env;

// Slice reducer
const slice = createSlice({
  name: 'files',
  initialState: {
    isLoading: false,
    error: null,
    currentProject: null,
    uploadedFiles: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFiles.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadFiles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentProject = action.payload.project;
        state.uploadedFiles = action.payload.uploadedFiles;
      })
      .addCase(uploadFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { reducer: filesReducer } = slice;

export const uploadFiles = createAsyncThunk('files/upload', async (data, { getState, rejectWithValue }) => {
  try {
    const uid = localStorage.getItem('accountUid');
    const projectId = data.id;
    const projectName = data.name;

    if (!projectId) {
      throw new Error('Invalid project ID');
    }
    localStorage.setItem('currentProject', projectId);

    const currentDate = new Date().toISOString();
    let project = await getProjectFromFirestore(uid, projectId);

    if (!project) {
      project = {
        id: projectId,
        name: projectName,
        updatedAt: currentDate,
        status: 'processing',
        files: [],
      };
    } else {
      project = {
        ...project,
        status: project.status || 'processing',
        files: project.files || [],
      };
    }

    const newFiles = (data.files || [])
      .filter((fs) => fs && fs.id === undefined)
      .map((fs) => {
        if (!fs) return null;
        const fileName = fs.name || 'unnamed';
        const sourceId = `${projectId}_${stringToHash(fileName)}`;

        return {
          id: sourceId,
          source: 'file',
          source_id: sourceId,
          user_id: uid,
          project_id: projectId,
          project_name: projectName,
          file_name: fileName,
          created_at: currentDate,
          updated_at: currentDate,
          ingestion_duration: 0,
          status: 'processing',
          mime_type: fs.type || 'application/octet-stream',
          last_chunk_processed: 0,
          key: fs.preview ? fs.preview.substring(fs.preview.lastIndexOf('/') + 1) : sourceId,
          name: fs.name || 'unnamed',
          size: fs.size || 0,
          path: fs.path || '',
          type: fileName.split('.').pop() || '',
          preview: fs.preview || '',
          lastModified: fs.lastModified || currentDate,
          lastModifiedDate: fs.lastModifiedDate || currentDate,
          isFavorited: false,
          remove_questions: false,
          shared: [],
          modifiedAt: currentDate,
          tags: [],
          num_words: 0,
          num_chunks: 0,
          num_tokens: 0,
          num_pages: 0,
        };
      })
      .filter(Boolean);

    project.files = [...project.files, ...newFiles];

    await updateProjectInFirestore(uid, projectId, project);

    const uploadPromises = newFiles.map(async (file) => {
      const metadataInfo = JSON.stringify({
        source: 'file',
        file_name: file.file_name,
        created_at: file.created_at,
        source_id: file.source_id,
        status: file.status,
        user_id: file.user_id,
        project_id: file.project_id,
        mime_type: file.mime_type,
        remove_questions: file.remove_questions,
      });

      const formData = new FormData();
      formData.append('metadata', metadataInfo);

      // @ts-ignore
      const originalFile = data?.files.find((f) => f && f.name === file.name);
      if (originalFile) {
        formData.append('file', originalFile);
      }
      formData.append('sourceId', file.source_id);
      formData.append('project_id', file.project_id);

      const URL = `${REACT_APP_SOFIA_API}/api/project/files/upsert`;
      const additionalHeaders = {
        CurrentProject: `${projectId}`,
      };
      const headers = {
        ...axios.defaults.headers.common,
        ...additionalHeaders,
      };
      return axios.post(URL, formData, {
        headers: headers,
        'Content-Type': 'multipart/form-data',
      });
    });

    const responses = await Promise.all(uploadPromises);
    return { project, uploadedFiles: responses.map((response) => response.data) };
  } catch (error) {
    console.error('File upload error:', error);
    return rejectWithValue(error.message || 'An error occurred during file upload');
  }
});

function stringToHash(string) {
  let hash = 0;

  if (string.length === 0) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }

  hash >>>= 0;

  return hash;
}
